
var Class = require('jsc/class.js');
var DOM = require('jsc/dom.js');
var Event = require('jsc/event.js');
var Debug = require('jsc/debug.js');
var Ajax = require('jsc/ajax.js');

var Model = module.exports.Model = Class(null, {
	__init__: function(columns, data) {
		this.columns = columns || null;
		this.raw = this.data = data || [];
		this.filterFunc = null;
	},

	doFilter: function() {
		if(this.filterFunc === null)
			this.data = this.raw;
		else {
			this.data = [];
			for(var i = 0; i < this.raw.length; i++) {
				var row = this.raw[i];
				if(this.filterFunc(row))
					this.data.push(row);
			}
		}
	},

	filter: function(ff) {
		this.filterFunc = ff;
		this.doFilter();
	},

	pickle: function(parms, name) {
		var len = this.getlength();
		for(var i = 0; i < len; i++) {
			var datarow = this.getrow(i);
			var rowname = name + '-' + i;
			for(var col = 0; col < this.columns.length; col++)
				if(datarow[col] === undefined)
					parms[rowname + '.' + this.columns[col]] = '';
				else
					parms[rowname + '.' + this.columns[col]] = datarow[col];
		}
		return parms;
	},

	setdata: function(data) {
		this.raw = data;
		this.doFilter();
	},

	getrow: function(row) {
		return this.data[row];
	},

	getlength: function() {
		return this.data.length;
	},

	add: function(datarow, row) {
		if(row)
			this.data.splice(row, 0, datarow);		/* XX: test */
		else
			this.data.push(datarow);
	},

	remove: function(row) {
		this.data.splice(row, 1);
	},

	find: function(idx, val, from, op) {
		for(var i = from; i < this.data.length; i++) {
			var test = this.data[i][idx];
			if(test != null) {
				if(op == '=' && test == val)
					return i;
				else if(op == 'in' && test.toLowerCase().indexOf(val.toLowerCase()) >= 0)
					return i;
				else if(op == 'ins' && test.indexOf(val) >= 0)
					return i;
			}
		}

		return -1;
	},

	whichRow: function(row) {
		for(var i = 0; i < this.data.length; i++)
			if(this.data[i] === row)
				return i;
		return null;
	},

	sort: function(idx, ascending) {
		var neg = -1, pos = 1;
		if(! ascending) {
			neg = 1;
			pos = -1;
		}
		this.data = this.data.sort(function(a, b) {
			var va = a[idx];
			var vb = b[idx];
			if(va == null)
				if(vb == null)
					return 0;
				else
					return neg;
			if(vb == null)
				return pos;
			if(va.toLowerCase)
				va = va.toLowerCase();
			if(vb.toLowerCase)
				vb = vb.toLowerCase();
			if(va < vb)
				return neg;
			if(va > vb)
				return pos;
			return 0;
		});
	}
});


var Grid = module.exports.Grid = Class(null, {
	gridColWidths: [],
	gridRowInfo: [],
	gridCellDefault: ['cell', 8, 0],
	gridRowHeight: 20,
	_widths: null,

	__init__: function() {
		this._grid = DOM.DIV({'className': 'grid'});
		this._cells = [];
	},

	replace: function(target) {
		target.parentNode.replaceChild(this._grid, target);
		this.layout();
	},

	/* add a row to the grid (always at the bottom) */
	addRow: function() {
		var row = this._cells.length;
		var nColumns = this.gridColWidths.length;

		/* calculate className */
		var cn = this.gridCellDefault[0];
		if(this.gridRowInfo[row])
			cn = this.gridRowInfo[row][0];

		/* create the cells */
		var divs = [];
		for(var col = 0; col < nColumns; col++) {
			var div = DOM.DIV({'className': cn});
			this.initCell(div, row, col);
			divs.push(div);
		}
		this._cells.push(divs);

		/* update layout if we're "live" */
		if(this._widths) {
			this._layoutRow(divs, row, row * this.gridRowHeight);
			DOM.place(this._grid, 0, 0, undefined, this._cells.length * this.gridRowHeight);
		}

		/* now add the cells (now that they're in the right place) */
//		var frag = document.createDocumentFragment();
		for(var col = 0; col < nColumns; col++)
//			frag.appendChild(divs[col]);
			this._grid.appendChild(divs[col]);

//		this._grid.appendChild(frag);
	},

	cell: function(row, col) {
		return this._cells[row][col];
	},

	/* remove a row from the grid at the specified position */
	removeRow: function() {
		var divs = this._cells.pop();
		for(var i = 0; i < divs.length; i++)
			this._grid.removeChild(divs[i]);

		/* update layout if we're "live" */
		if(this._widths)
			DOM.place(this._grid, 0, 0, undefined, this._cells.length * this.gridRowHeight);
	},

	initCell: function(div, row, col) {
		/* default cell initialisation */
		div.appendChild(document.createTextNode(''));
	},

	layoutCell: function(div, row, col, innerWidth, innerHeight) {
		/* default cell layout */
	},

	_calcWidths: function(width) {
		var nFlex = 0, fixed = 0, nColumns = this.gridColWidths.length;
		for(var col = 0; col < nColumns; col++) {
			if(this.gridColWidths[col])
				fixed += this.gridColWidths[col];
			else
				nFlex++;
		}
		var flexSize;
		if(nFlex)
			flexSize = Math.floor((width - fixed) / nFlex);
		this._widths = [];
		for(var col = 0; col < nColumns; col++) {
			if(this.gridColWidths[col])
				this._widths.push(this.gridColWidths[col]);
			else
				this._widths.push(flexSize);
		}
	},

	_layoutRow: function(divs, row, y) {
		var horzAdjust = this.gridCellDefault[1];
		if(this.gridRowInfo[row])
			horzAdjust = this.gridRowInfo[row][1];

		var x = 0, innerWidth;
		var nCols = this._widths.length;
		for(var col = 0; col < nCols; col++) {
			innerWidth = this._widths[col] - horzAdjust;
			DOM.place(divs[col], x, y, innerWidth);
			x += this._widths[col];
			this.layoutCell(divs[col], row, col, innerWidth, this.gridRowHeight);
		}
	},

	/* (re)layout the grid based on measured dimensions. Should be called whenever outer grid size changes. */
	layout: function() {
		this._totwidth = DOM.getWidth(this._grid);
		this._calcWidths(this._totwidth);
		var nRows = this._cells.length;

		for(var row = 0; row < nRows; row++)
			this._layoutRow(this._cells[row], row, row * this.gridRowHeight);

		DOM.place(this._grid, 0, 0, undefined, nRows * this.gridRowHeight);
	}
});

