/*
		livegrid.jsc,

	TODO:
		* test with relayout

 */

//#include <base.js>
var Class = require('jsc/class.js');
var DOM = require('jsc/dom.js');
var Event = require('jsc/event.js');
var Debug = require('jsc/debug.js');
var Ajax = require('jsc/ajax.js');
var Model = require('./grid.js').Model;


var Scroller = Class(null, {
	__init__: function(evtTarget, parElem, rows, rowHeight, right, top) {
		this.evtTarget = evtTarget;
		this.scroller = DOM.style({'overflowY': 'scroll', 'position': 'absolute', 'width': '18px', 'left': (right - 18) + 'px', 'top': top + 'px', 'height': (rows * rowHeight) + 'px'}, DOM.DIV({'className': 'scroller'}, this.content = DOM.DIV()));
		this.unitheight = rowHeight;
		this.currow = 0;
		Event.observe(this.scroller, 'scroll', this, this.onScroll);
		DOM.addNodes(parElem, this.scroller);

		this.nPage = rows;
	},

	setbounds: function(nMax) {
		this.nMax = nMax;
		DOM.place(this.content, undefined, undefined, undefined, nMax * this.unitheight);
	},

	onScroll: function(evt) {
		var pos = this.scroller.scrollTop;
		var row = Math.floor(pos / this.unitheight);
		this.currow = row;
		Event.stop(evt)
		this.evtTarget.onScroll(row);
	},

	setpos: function(row) {
		this.scroller.scrollTop = row * this.unitheight;
	}
});



/*
	cols should be layed out as follows:
var cols = [
	[label, fieldname, colwidth, searchoperator, renderfunc],
	...
];

	where searchwidth is null/undefined for no search
	searchoperator is one of '=', 'in', 'ins' (ins is case sensitive 'in' operator)
	colwidth is a pixel width of the column, null for 'expand'
	renderfunc is an optional function that returns innerHTML for the cell when rendered:
	function renderlinktoid(row, idx) {
		return '<a href="gotopage?id=' + row[0] + '">' + row[idx] + '</a>';
	}
	if renderfunc is missing it uses the following:
	function rendernormal(row, idx) {
		var val = row[idx];
		if(val == null)
			return '';
		return val;
	}
 */


var LiveGrid = module.exports = Class(null, {
	__init__: function(target, cols, visMax, colRenderFunc, reloadFunc, selectCB, primaryActivate, secondaryActivate, tabindex, model, imgAsc, imgDesc, imgWorking) {
		this.cols = cols;
		this.visMax = visMax;
		this.toprow = 0;
		this.reloadFunc = reloadFunc;
		this.primaryActivate = primaryActivate;
		this.secondaryActivate = secondaryActivate;
		this.colRenderFunc = colRenderFunc;
		this.selectCallBack = selectCB;

		this.cellHorzExtra = 8;	/* 6px padding, 2px border */
		this.filterHorzExtra = 2;	/* 2px border */
		this.inputHorzExtra = 4;	/* 2px border */

		var grid = DOM.DIV({'className': 'grid'}, DOM.DIV({'className': 'heading'}));
		DOM.place(grid, 0, 0, undefined, (visMax + 2) * 20);
		this.labels = this.addrow(grid, 'headingcell');
		this.filters = this.addrow(grid, 'filtercell');
		this.cursor = DOM.DIV({'className': 'cursor'});
		this.cursorPos = 0;
		this.cells = [];
		grid.appendChild(this.cursor);
		for(row = 0; row < visMax; row++) {
			this.cells.push(this.addrow(grid, undefined, this.rowHandler(row), row));
//			div = DOM.DIV({'className': 'row'});
//			grid.appendChild(div);
		}
		target.parentNode.replaceChild(grid, target);

		this.grid = grid;
		this.layout();

		/* fill in the headings */
		for(var col = 0; col < this.labels.length; col++) {
			this.labels[col].innerHTML = this.cols[col][0];
			this.labels[col].style.cursor = 'pointer';
			Event.observe(this.labels[col], 'click', this, this.sortHandler(col));

			var i = DOM.INPUT({'type': 'text'});
			if(tabindex)
				i.setAttribute('tabindex', tabindex++);
			Event.observe(i, 'keydown', this, this.searchHandler(col, i));
			if(! this.firstinput)
				this.firstinput = i;
			DOM.place(i, undefined, undefined, this.widths[col] - this.filterHorzExtra - this.inputHorzExtra);
			this.filters[col].appendChild(i);

			/* also initialise the column renders */
			if(! cols[col][3])
				cols[col][3] = this.cellRender;
		}
		var accesskey = target.getAttribute('accesskey');
		if(accesskey && this.firstinput) {
			this.firstinput.setAttribute('accesskey', accesskey);
			Event.observe(this.firstinput, 'focus', grid, function(evt) {
				if(this.scrollIntoView)
					this.scrollIntoView(false);
			});
		}

		this.imgAsc = DOM.style({'marginLeft': '3px', 'verticalAlign': 'middle'}, DOM.IMG({'src': imgAsc || '/static/sort_asc.gif'}));
		this.imgDesc = DOM.style({'marginLeft': '3px', 'verticalAlign': 'middle'}, DOM.IMG({'src': imgDesc || '/static/sort_desc.gif'}));
		var div = DOM.style({'position': 'absolute', 'right': '1px', 'top': '2px', 'cursor': 'pointer', 'width': '16px', 'height': '16px'}, DOM.DIV());
		this.imgWorking = DOM.IMG({'src': imgWorking || '/static/throbber-small.gif'});
		DOM.hide(this.imgWorking, true);
		DOM.addNodes(div, this.imgWorking);
		DOM.addNodes(this.labels[0].parentNode, div);
		Event.observe(div, 'click', this, this.reload);
		this.ascOwner = null;
		this.descOwner = null;
		this.sortBy = 0;
		this.ascending = true;
		this.model = model || new Model();
		this.setArrow();
		this.sb = new Scroller(this, this.grid, visMax, 20, this.totwidth, 40);
	},

	rowHandler: function(row) {
		return function(evt) {
			this.updateSelected(row);
			this.setcursor(this.toprow + row);
		};
	},

	updateSelected: function(row){

		for(var currentRow = 0; currentRow < this.cells.length; currentRow++){
			var bg = '#fff';
			var fc = '#6B6B6B'
			if(currentRow == row){
				fc = '#fff'
				bg = '#3879d9';
			}
			else{
				if(currentRow % 2 != 0)
					bg = '#f0f0f0';
			}
			if(currentRow == this.selectedRow || currentRow == row){
				for(var col = 0; col < this.cells[currentRow].length; col++){
					this.cells[currentRow][col].style.backgroundColor = bg;
					this.cells[currentRow][col].style.color = fc;
				}
			}
		}
		this.selectedRow = row;
		this.selectCallBack(this.model.getrow(this.selectedRow + this.toprow));
		this.render();
	},

	addrow: function(par, cn, handler, y) {
		cn = cn || 'cell';
		var divs = [];
		var cols = this.cols;
		for(var col = 0; col < cols.length; col++) {
			var div = DOM.DIV({'className': cn});
			if(cols[col][4])
				div.appendChild(cols[col][4](y, col, div));
			else
				div.appendChild(DOM.TXT(''));
			if(handler)
				Event.observe(div, 'click', this, handler);
			divs.push(div);
			par.appendChild(div);
		}
		return divs;
	},

	layoutRow: function(row, y, horzExtra, bg) {
		var x = 0;
		for(var col = 0; col < row.length; col++) {
			DOM.place(row[col], x, y, this.widths[col] - horzExtra);
			x += this.widths[col];
			row[col].style.backgroundColor = bg
		}
	},

	calcWidths: function(width) {
		var nFlex = 0, fixed = 0;
		for(var col = 0; col < this.cols.length; col++) {
			if(this.cols[col][2])
				fixed += this.cols[col][2];
			else
				nFlex++;
		}
		var flexSize;
		if(nFlex)
			flexSize = Math.floor((width - fixed) / nFlex);
		this.widths = [];
		for(var col = 0; col < this.cols.length; col++) {
			if(this.cols[col][2])
				this.widths.push(this.cols[col][2]);
			else
				this.widths.push(flexSize);
		}
	},

	layout: function() {
		this.totwidth = DOM.getWidth(this.grid);
		this.calcWidths(this.totwidth - 18);	/* -18 for scrollbar */
		this.layoutRow(this.labels, 0, this.cellHorzExtra);
		this.layoutRow(this.filters, 20, this.filterHorzExtra);
		var y = 40;
		for(var row = 0; row < this.cells.length; row++) {
			var bg = '#fff';
			if(row % 2 != 0)
				bg = '#f0f0f0';

	 		this.layoutRow(this.cells[row], y, this.cellHorzExtra, bg);
// 			this.layoutRow(this.cells[row], y, this.cellHorzExtra);
			y += 20;
		}
		DOM.place(this.cursor, undefined, undefined, this.totwidth - 18);
	},

	searchHandler: function(col, input) {
		return function(evt) {
			this.searchInput(evt, col, input);
		};
	},

	searchInput: function(evt, idx, input) {
		if(evt.keyCode == Event.KEY_RETURN) {
			if(evt.ctrlKey) {
				var row = this.model.getrow(this.cursorPos);
				if(row) {
					if(evt.shiftKey) {
						if(this.secondaryActivate)
							this.secondaryActivate(row);
					}
					else {
						if(this.primaryActivate)
							this.primaryActivate(row);
					}
				}
			}
			else
				this.search(idx, input.value, this.cols[idx][1]);
		}
		else if(evt.keyCode == Event.KEY_DOWN) {
			if(evt.ctrlKey)
				this.sort(idx, false);
			else
				this.movecurs(1);
		}
		else if(evt.keyCode == Event.KEY_UP) {
			if(evt.ctrlKey)
				this.sort(idx, true);
			else
				this.movecurs(-1);
		}
		else if(evt.keyCode == Event.KEY_PGUP)
			this.movecurs(-Math.floor(this.visMax / 2));
		else if(evt.keyCode == Event.KEY_PGDOWN)
			this.movecurs(Math.floor(this.visMax / 2));
	},

	search: function(idx, val, op) {
		var res = this.model.find(idx, val, this.cursorPos + 1, op);
		if(res >= 0) {
			/* we have a match */
			return this.setcursor(res);
		}

		/* try wrapping to the beginning */
		res = this.model.find(idx, val, 0, op);
		if(res >= 0) {
			/* wrapping gave a match */
			return this.setcursor(res);
		}
		else {
			/* no match at all */
			return;
		}
	},

	focus: function() {
		this.firstinput.focus();
	},

	movecurs: function(delta) {
		var pos = this.cursorPos + delta;

		/* clip cursor */
		if(pos < 0)
			pos = 0;
		if(pos >= this.model.getlength())
			pos = this.model.getlength() - 1;
		if(pos != this.cursorPos)
			this.setcursor(pos);
	},

	setcursor: function(pos) {
		/* scroll cursor into view */
		var scroll = false;
		if(pos < this.toprow) {
			this.toprow = pos;
			scroll = true;
		}
		if(pos >= (this.toprow + this.cells.length)) {
			this.toprow = pos - this.cells.length + 1;
			scroll = true;
		}
		this.cursorPos = pos;
		if(scroll) {
			this.sb.setpos(this.toprow);
			this.render();
			return true;
		}

		this.renderCurs();
		return false;		/* no render required */
	},

	setArrow: function(oldidx, oldasc, idx, asc) {
		if(this.ascOwner)
			this.ascOwner.removeChild(this.imgAsc);
		if(this.descOwner)
			this.descOwner.removeChild(this.imgDesc);
		this.ascOwner = null;
		this.descOwner = null;
		var owner = this.labels[this.sortBy];
		if(this.ascending) {
			owner.appendChild(this.imgAsc);
			this.ascOwner = owner;
		}
		else {
			owner.appendChild(this.imgDesc);
			this.descOwner = owner;
		}
	},

	sortHandler: function(rowidx) {
		return function() {
			this.sort(rowidx);
		};
	},

	sort: function(rowidx, dir) {
		if(dir == undefined) {
			if(rowidx == this.sortBy)
				this.ascending = ! this.ascending;
			else
				this.ascending = true;
		}
		else
			this.ascending = dir;
		this.sortBy = rowidx;
		this.setArrow();

		/* preserves cursor pos */
		var row = this.model.getrow(this.cursorPos);
		this.model.sort(this.sortBy, this.ascending);
		row = this.model.whichRow(row);
		this.setcursor(row);
		this.render();
	},

	onScroll: function(toprow) {
		if(toprow != this.toprow) {
			var oldTop = this.toprow;
			this.toprow = toprow;
			if(!(typeof(this.selectedRow) === 'undefined')){
				var dif = toprow - oldTop;
				this.updateSelected(this.selectedRow - dif);
			}
			this.render();
		}
	},

	cellRender: function(row, idx, cell) {
		var val = '';
		if(row != null && row[idx] != null)
			val = row[idx];
		cell.firstChild.data = val;
	},

	renderCurs: function() {
		if(this.cursorPos >= this.toprow && this.cursorPos < (this.toprow + this.cells.length) && this.model.getlength()) {
			DOM.place(this.cursor, 0, (this.cursorPos - this.toprow + 2) * 20);
			DOM.show(this.cursor);
		}
		else
			DOM.hide(this.cursor);
	},

	render: function() {
		this.renderCurs();

		/* populate the visible rows with the given data */
		for(var y = 0; y < this.cells.length; y++) {
			var row = this.cells[y];
			var datarow = this.model.getrow(y + this.toprow);
			if(this.colRenderFunc)
				this.colRenderFunc(datarow, row);
			for(var col = 0; col < row.length; col++) {
				this.cols[col][3](datarow, col, row[col]);
			}
		}
	},

	filter: function(tf) {
		this.model.filter(tf);
	},

	update: function() {
		this.model.doFilter();
		this.toprow = 0;
		this.cursorPos = 0;
		this.sb.setbounds(this.model.getlength());
		this.sb.setpos(0);
		this.model.sort(this.sortBy, this.ascending);
		this.render();
	},

	populate: function(data) {
		this.model.setdata(data);
		this.toprow = 0;
		this.cursorPos = 0;
		this.sb.setbounds(this.model.getlength());
		this.model.sort(this.sortBy, this.ascending);
		this.render();
	},

	retrieve: function(url) {
		this._lasturl = url;
		var tbl = this;
		DOM.show(this.imgWorking, true);
		Ajax.get(url, function(data, status) {
			data = eval(data);
			tbl.populate(data);
			DOM.hide(tbl.imgWorking, true);
		});
	},

	reload: function(evt) {
		if(this.reloadFunc){
			DOM.show(this.imgWorking, true);
			this.reloadFunc();
		}
		else
			this.retrieve(this._lasturl);
	},

	getVisibleRow: function(y) {
		return this.model.getrow(y + this.toprow);
	},

	remove: function(row) {
		/* XX: if the cursor's at the end of the list, and we could scroll upward, then shouldn't we? */
		this.model.remove(row);
		this.movecurs(0);
		this.render();
	}
});

