
module.exports = pageload;

var ChosenMultiselect = require('jsc/chosenmultiselect.js');
var MultiSelect = require('jsc/multiselect.js');
var Form = require('jsc/form.js');
var Event = require('jsc/event.js');
var $ = require('jsc/base.js').$;
var DOM = require('jsc/dom.js');

function parseDuration(duration) {
	var parts = duration.split(/:/);
	var len = parts.length;
	var ontime = 0;
	if(len == 0) {
		return null;
	}
	if(len >= 1)
		ontime = parseInt(parts[len - 1]);
	if(len >= 2)
		ontime += parseInt(parts[len - 2] * 60);
	if(len >= 3)
		ontime += parseInt(parts[len - 3] * 3600);
	return ontime;
}

function validemail(address){
	regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
	return address.match(regex);
}

function findFirstElem(elem, type) {
	if (typeof type == 'undefined')
		type = 'TR'

	if (elem.nodeName == type)
		return elem
	else
		return findFirstElem(elem.parentElement, type);
}

function addTrans(elem, removePar) {
	elem.style.maxHeight = "0px";
	if (elem.className.indexOf('trans_hidden') == -1)
		elem.className += ' trans_hidden';
	if(removePar){
		var par = findFirstElem(elem, 'FIELDSET');
		par.style.padding = 0;
	}
}

function removeTrans(elem, addPar) {
	DOM.show(elem);
	elem.style.maxHeight = "500px";
	var idx = elem.className.indexOf('trans_hidden')
	if (idx != -1)
		elem.className = elem.className.substring(0, idx - 1);
	if (addPar){
		var par = findFirstElem(elem, 'FIELDSET');
		par.style.padding = '';
	}
}

function filldate(f, name, d, year) {
	if (year == undefined)
		year = baseYear;
	f.element(name + '.day').selectedIndex = d.getDate() - 1;
	f.element(name + '.month').selectedIndex = d.getMonth();
	f.element(name + '.year').selectedIndex = d.getFullYear() - year;
}

function getdate(f, name) {
	var year = parseInt(f.element(name + '.year').value);
	var month = f.element(name + '.month').selectedIndex;
	var day = f.element(name + '.day').selectedIndex + 1;
	withTime = f.element(name + '.hour') !== undefined;
	if(withTime) {
		var hour = f.element(name + '.hour').selectedIndex + 1;
		var minute = f.element(name + '.minute').selectedIndex;
		if(f.element(name + '.ampm').selectedIndex > 0)
			hour += 12;
		return new Date(year, month, day, hour, minute);
	}
	return new Date(year, month, day);
}

function setDone(elem, redir) {
	var fa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
	var spanTxt = DOM.SPAN(null, "Done");
	DOM.removeChildren(elem);;
	DOM.addNodes(elem, fa, spanTxt);
	if (redir) {
		window.setTimeout(function () {
			if(typeof(redir) == 'function')
				redir();
			else
				window.location = redir;
		}.bind(this), 2500);
	}
}

function setError(elem, stat) {
	var fa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-exclamation'}, ''));
	var spanTxt = DOM.SPAN(null, "Failed (" + stat + ")");
	DOM.removeChildren(elem);
	DOM.addNodes(elem, fa, spanTxt);
}


function validatealert(parms){
	this.clearErrors();
	parms['alertid'] = alertid;
	parms['alerttype'] = parms['type'];
	delete parms['type'];
	delete parms['due.day'];
	delete parms['due.month'];
	delete parms['due.year'];
	delete parms['timedue.day'];
	delete parms['timedue.month'];
	delete parms['timedue.year'];
	parms['due'] = parseInt(getdate(f, 'due') / 1000);
	parms['separate'] = newalert;
	if (newalert || unitdata.length == 1)
		parms['units'] = [unitdata[0]['id']];
	else
		parms['units'] = parms['selectto'];

	var failure = false;
	if ($('errorUL')) {
		DOM.hide($('error_div'));
		DOM.removeNodes($('error_content'), $('errorUL'));
	}
	var errorList = DOM.UL({'id': 'errorUL'}, '');

	if(parms['units'].length == 0){
		DOM.addNodes(errorList, DOM.LI(null, 'At least one vehicle must be assigned to this reminder'));
		f.setError('selectto');
		failure = true;
	}

	if(parms['alerttype'] == 'enginetime') {
		parms['due'] = parseDuration(parms['enginetime.due']);
		parms['notify'] = parseDuration(parms['enginetime.notify']);
		parms['remind'] = parseDuration(parms['enginetime.remind']);
		if(! parms['due']) {
			DOM.addNodes(errorList, DOM.LI(null, 'Must specify a due time for this alert'));
			f.setError('enginetime.due');
			failure = true;
		}
	}
	delete parms['enginetime.due'];
	delete parms['enginetime.notify'];
	delete parms['enginetime.remind'];

	if((parms['alerttype'] == 'distance') || (parms['alerttype'] == 'hubodistance')) {
		parms['due'] = parms['distance.due'];
		parms['notify'] = parms['distance.notify'];
		parms['remind'] = parms['distance.remind'];
	}
	delete parms['distance.due'];
	delete parms['distance.notify'];
	delete parms['distance.remind'];

	if(parms['alerttype'] == 'time') {
		parms['due'] = parseInt(getdate(f, 'timedue') / 1000);
		parms['notify'] = parms['time.notify'];
		parms['notifyunit'] = parms['time.notifyunit'];
		parms['remind'] = parms['time.remind'];
		parms['remindunit'] = parms['time.remindunit'];
	}
	delete parms['time.notify'];
	delete parms['time.notifyunit'];
	delete parms['time.remind'];
	delete parms['time.remindunit'];

	if ((parms['alerttype'] == 'reminder') || (parms['alerttype'] == 'distancealert') || (parms['alerttype'] == 'distance') || (parms['alerttype'] == 'hubodistance') || (parms['alerttype'] == 'time')){
		if(parms['alerttype'] == 'distancealert' && isNaN(parseInt(parms['dueat']))){
			DOM.addNodes(errorList, DOM.LI(null, 'Due kms must be numerical'));
			f.setError('dueat');
			failure = true;
		}
		if(parms['name'] == ''){
			DOM.addNodes(errorList, DOM.LI(null, 'Please set a name for this reminder'));
			f.setError('name');
			failure = true;
		}
		if((parms['alerttype'] == 'reminder') && isNaN(parseInt(parms['thresholdtime']))){
			DOM.addNodes(errorList, DOM.LI(null, 'Reminder threshold must be numerical'));
			f.setError('thresholdtime');
			failure = true;
		}
	}
	else if(parms['alerttype'] == 'speeding'){
		if(isNaN(parseInt(parms['Tspeeding']))){
			DOM.addNodes(errorList, DOM.LI(null, 'Speeding trigger level must be numerical'));
			f.setError('Tspeeding');
			failure = true;
		}
	}
	else if(parms['alerttype'] == 'power'){
		if(parms['Tbattery'] == '' || isNaN(parms['Tbattery'])){
			DOM.addNodes(errorList, DOM.LI(null, 'Battery trigger level must be numerical'));
			f.setError('Tbattery');
			failure = true;
		}
	}
	if(parms['anonsubs']) {
		var anon = parms['anonsubs'].split(',');
		parms['anonsubs'] = [];
		if(anon.length > 0 && anon[0] != ''){
			for (var idx = 0; idx < anon.length; idx++){
				var email = validemail(anon[idx].trim());
				if(!email){
					DOM.addNodes(errorList, DOM.LI(null, 'One or more "non-user" subscribers seems to be invalid'));
					failure = true;
					f.setError('anonsubs');
					break;
				}
				else
					parms['anonsubs'].push(email);
			}
		}
	}
	if (failure) {
		DOM.show($('error_div'));
		DOM.hide($('noticediv'));
		DOM.addNodes($('error_content'), errorList);
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		return null;
	}


	delete parms['selectto'];
	return parms;
}


function pageload() {
	if ($('userlist')) {
		var multi = new ChosenMultiselect($('userlist'), 'subscriptions', userdata, subs);
		if(alertid == 'new')
			multi.addSelectedById(thisuser);
	}
	if ($('multiselectdiv'))
		var sel = new MultiSelect($('multiselectdiv'), 'Filter Vehicles', unitdata, assignment);

	if($('submitalert')){
		f = new Form('alert', 'updatealert', null, $('submitalert'));

		var currentYear = new Date().getFullYear();
		var maxYear = 18;
		for (var i = 0; i < maxYear; i++) {
			DOM.addNodes($('due.year'), DOM.OPTION(null, String(currentYear + i)));
			DOM.addNodes($('timedue.year'), DOM.OPTION(null, String(currentYear + i)));
		}
		if (alertdata.length > 1 && alertdata[0] == 'reminder')
			d = new Date(alertdata[1]['due'] * 1000);
		else
			d = new Date();

		filldate(f, 'due', d, currentYear);

		if (alertdata.length > 1 && alertdata[0] == 'time') {
			d = new Date(alertdata[1]['due'] * 1000);
			filldate(f, 'timedue', d, currentYear);
		}

		function setUpPage() {
			if ($('multiselectdiv'))
				sel.updateAvailable(alertunits[$('remindertype').value]);
			addTrans($('reminder_name'), true);
			addTrans($('enginetime_parms'), true);
			addTrans($('due_on'));
			addTrans($('due_at'));
			addTrans($('due_soon'), true);
			addTrans($('parms'), true);
			addTrans($('place_parms'), true);
			addTrans($('temp_parms'), true);
			addTrans($('temp_parms'), true);
			addTrans($('distance_parms'), true);
			addTrans($('time_parms'), true);
			switch ($('remindertype').value) {
				case 'reminder':
					removeTrans($('reminder_name'), true);
					removeTrans($('due_on'));
					removeTrans($('due_soon'), true);
					removeTrans($('time_threshold'));
					addTrans($('kms_threshold'));
					break;
				case 'time':
					removeTrans($('reminder_name'), true);
					removeTrans($('time_parms'), true);
					break;
				case 'distancealert':
					removeTrans($('reminder_name'), true);
					removeTrans($('due_at'));
					removeTrans($('due_soon'), true);
					addTrans($('time_threshold'));
					removeTrans($('kms_threshold'));
					break;
				case 'distance':
				case 'hubodistance':
					removeTrans($('reminder_name'), true);
					removeTrans($('distance_parms'), true);
					break;
				case 'speeding':
					removeTrans($('parms'), true);
					addTrans($('battery_parms'));
					removeTrans($('speeding_parms'));
					removeTrans($('speeding_parms_debounce'));
					addTrans($('moving_parms'));
					break;
				case 'moving':
					removeTrans($('parms'), true);
					addTrans($('battery_parms'));
					addTrans($('speeding_parms'));
					addTrans($('speeding_parms_debounce'));
					removeTrans($('moving_parms'));
					break;
				case 'power':
					removeTrans($('parms'), true);
					removeTrans($('battery_parms'));
					addTrans($('speeding_parms'));
					addTrans($('speeding_parms_debounce'));
					addTrans($('moving_parms'));
					break;
				case 'place':
					removeTrans($('place_parms'), true);
					break;
				case 'temperature':
					removeTrans($('temp_parms'), true);
					break;
				case 'enginetime':
					removeTrans($('reminder_name'), true);
					removeTrans($('enginetime_parms'), true);
					break;
			}
		}

		setUpPage();
		Event.observe($('remindertype'), 'change', window, setUpPage);
		f.validate = validatealert;
		f.submitted  = function(data){
			if (data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			data = eval(data);
			if (data == true){
				setDone(f.elStatus, window.location = document.referrer);
			}
			else{
				setError(f.elStatus, 'Error updating alert');
			}
		}
	}
}

