/*
		main.js,
			JS Code for most pages.

 */


/*
#include <form.js>
#include <table.js>
#include <debug.js>
#include <dom.js>
#include <repr.js>
#include <png.js>
#include <cookie.js>
#include <datetime.js>
#include <proj4js.js>
#include <livegrid.jsc>
#include <grid.jsc>
#include <jsonp.js>
#include <tween.js>
#include <litebox.jsc>
#include multiselect.js
#include chosenmultiselect.js
#include util.js
*/

var jQuery = require('jquery');
var CDateTime = require('jsc/datetime.js').CDateTime;
var CDate = require('jsc/datetime.js').CDate;
var Timezone = require('jsc/datetime.js').Timezone;
var $ = require('jsc/base.js').$;
var Proj4js = require('jsc/proj4js.js');
var Tween = require('jsc/tween.js');
var PNGLoader = require('jsc/png.js');
var Table = require('jsc/table.js');
var DOM = require('jsc/dom.js');
var Ajax = require('jsc/ajax.js');
var unrepr = require('jsc/unrepr.js');
var JsonP = require('jsc/jsonp.js');
var str = require('jsc/str.js');
var ChosenMultiselect = require('jsc/chosenmultiselect.js');
var MultiSelect = require('jsc/multiselect.js');
var Form = require('jsc/form.js');
var Event = require('jsc/event.js');
var Class = require('jsc/class.js');
var Browser = require('jsc/browser.js');
var lightBox = require('./js/litebox.js');
var LiveGrid = require('./js/livegrid.js');

tween = new Tween.Tweener(50);

png = new PNGLoader('/static/');
imgpng = new PNGLoader('/images/');

if(!Date.now){
	Date.now = function() { return +new Date; }
};

Object.keys = Object.keys || (function () {
	var hasOwnProperty = Object.prototype.hasOwnProperty,
		hasDontEnumBug = !{toString:null}.propertyIsEnumerable("toString"),
		DontEnums = [
			'toString',
			'toLocaleString',
			'valueOf',
			'hasOwnProperty',
			'isPrototypeOf',
			'propertyIsEnumerable',
			'constructor'
		],
		DontEnumsLength = DontEnums.length;

	return function (o) {
		if (typeof o != "object" && typeof o != "function" || o === null)
			throw new TypeError("Object.keys called on a non-object");

		var result = [];
		for (var name in o) {
			if (hasOwnProperty.call(o, name))
				result.push(name);
		}

		if (hasDontEnumBug) {
			for (var i = 0; i < DontEnumsLength; i++) {
				if (hasOwnProperty.call(o, DontEnums[i]))
				result.push(DontEnums[i]);
			}
		}

		return result;
	};
})();

Proj4js.defs["EPSG:3395"] = '+proj=merc +lon_0=0 +k=1.000000 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
Proj4js.defs["EPSG:3857"] = Proj4js.defs["EPSG:3875"]; //our version of proj4js doesn't have google's mercator correctly defined


months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
baseYear = 2005;

function parseDuration(duration) {
	var parts = duration.split(/:/);
	var len = parts.length;
	var ontime = 0;
	if(! duration.match(/^\d+(:\d+){0,2}$/))
		return null;

	ontime = parseInt(parts[0]);

	if(len >= 2) {
		ontime *= 60;
		ontime += parseInt(parts[1]);
	}
	else {
		ontime *= 3600;	// number of hours for 1 field
		return ontime;
	}

	if(len >= 3) {
		ontime *= 60;
		ontime += parseInt(parts[2]);
	}
	else
		ontime *= 60;

	return ontime;
}


reportTypes = {
	'activityreport': 'Activity Report',
	'afterhoursreport': 'After Hours Report',
	'mileagereport': 'Mileage Report',
	'auxreport': 'Auxillary Report',
	'ontimereport': 'On Time Report',
	'stopsreport': 'Stops Report',
	'locationreport': 'Location Report',
	'vehiclestatusreport': 'Vehicle Status Report',
	'schedulingreport': 'Scheduling Report',
	'schedulingv2': 'Scheduling Report',
	'export': 'Export data Report',
	'incidentreport': 'Incident Report',
	'incidentreportv2': 'Incident Report',
	'timebyplacereport': 'Time by Place Report',
	'mileagebyplacereport': 'Mileage by Place Report',
	'rucreport': 'Road User Charges Report',
	'messagingreport': 'Messaging Report',
	'timeonsitereport': 'Time on Site Report',
	'dailysummaryreport': 'Daily Summary Report',
	'deliveryreport': 'Delivery Report',
	'safetyreport': 'Safety Report'
}

states = {
	1: 'Moving',
	0: 'Stopped'
}

function filldate(f, name, d, year) {
	if (year == undefined)
		year = baseYear;
	f.element(name + '.day').selectedIndex = d.getDate() - 1;
	f.element(name + '.month').selectedIndex = d.getMonth();
	f.element(name + '.year').selectedIndex = d.getFullYear() - year;
}

function getdate(f, name) {
	var year = parseInt(f.element(name + '.year').value);
	var month = f.element(name + '.month').selectedIndex;
	var day = f.element(name + '.day').selectedIndex + 1;
	withTime = f.element(name + '.hour') !== undefined;
	if(withTime) {
		var hour = f.element(name + '.hour').selectedIndex + 1;
		var minute = f.element(name + '.minute').selectedIndex;
		if(f.element(name + '.ampm').selectedIndex > 0)
			hour += 12;
		return new Date(year, month, day, hour, minute);
	}
	return new Date(year, month, day);
}

function fillyears(f, name){
	var currentDate = new Date();
	var maxYear = currentDate.getFullYear();
	for(var year = baseYear; year <= maxYear; year++){
		opt = DOM.OPTION(null, year.toString());
		DOM.addNodes(f.element(name + '.year'), opt);
	}
}

var ActionsList = Class(null, {
	__init__: function(control, unorderedList) {

		this.control = control;
		if(!unorderedList){
			this.unorderedList = DOM.UL({'className': 'dropdown-menu'}, '');
			DOM.addNodes(this.control, this.unorderedList);
		}
		else
			this.unorderedList = unorderedList;
		this.baseClassName = control.className;
		Event.observe(this.control, 'click', this, this.open.bind(this));
	},


	open: function(evt){
		if(this.control.className == this.baseClassName + 'open')
			this.control.className = this.baseClassName;
		else if (this.control.className == this.baseClassName) {
			var elements = document.getElementsByClassName('btn open');
			for (var i = 0; i < elements.length; i++) {
				elements[i].className = 'btn';
			}
			var elements = document.getElementsByClassName('btn_right open');
				for (var i = 0; i < elements.length; i++) {
					elements[i].className = 'btn_right';
			}
			this.control.className = this.baseClassName + ' open';
			Event.stop(evt);
			Event.observe(document, 'click', this, this.close.bind(this));
		}
	},

	close: function(evt){
		Event.unobserveAll(document);
		var elements = document.getElementsByClassName('btn open')
		for (var i = 0; i < elements.length; i++) {
			elements[i].className = 'btn';
		}
		var elements = document.getElementsByClassName('btn_right open')
		for (var i = 0; i < elements.length; i++) {
			elements[i].className = 'btn_right';
		}
	}
})

var OdoOffset = Class(null, {
	__init__: function(odoReading){
		this.odo = odoReading;
		this.offset = 0;
		this.controls = {}
		this.pending = [];
		this.ready = false;
		this.ops = [];
	},

	append: function(starttime, endtime, startSpan, endSpan){
		this.pending.push(starttime);
		if(startSpan === null)
			this.controls[starttime] = {}
		else
			this.controls[starttime] = {'startspan': startSpan, 'endspan': endSpan};
		Ajax.get('/myaccount/getunitdistsummary?unitid=' + vehicleid + '&starttime=' + parseInt(starttime) + '&endtime=' + parseInt(endtime), this.receiveRecords.bind(this));
	},

	receiveRecords: function(data, status){
		if (status == 200) {
			var distance = 0;
			var distRecords = unrepr(data);
			if(distRecords){
				var starttime = distRecords['starttime'];
				var endtime = distRecords['endtime'];
				distRecords = distRecords['activity'];
				for(var i = 0; i < distRecords.length; i++){
					distance += distRecords[i]['distance'];
				}
				var pendingIdx = this.pending.indexOf(starttime);
				if(this.pendingIdx != -1)
					this.pending.splice(pendingIdx, 1);
				if(starttime in this.controls)
					this.controls[starttime]['distance'] = distance;

				console.log(starttime, endtime, distance);
			}
		}
		if(this.pending.length == 0 && this.ready)
		 	this.calculate();
	},

	onReady: function(){
		this.ready = true;
		if(this.pending.length == 0)
			this.calculate();
	},

	calculate: function(){
		var objKeys = Object.keys(this.controls).sort();
		objKeys.reverse()
		for(var i = 0; i < objKeys.length; i++){
			var curObj = this.controls[objKeys[i]];
			if('startspan' in curObj){
				var endSpan = $(curObj['endspan']);
				var startSpan = $(curObj['startspan']);
				if(endSpan.innerHTML == '')
					endSpan.innerHTML = '&nbsp;&mdash;&nbsp;' + (this.odo - this.offset).toFixed(2);
				DOM.setTxt(startSpan, ' ' + (this.odo - (this.offset + (curObj['distance'] / 1000))).toFixed(2));
			}
			this.offset += curObj['distance'] / 1000;
		}
	}
});

var InputCalendar = Class(null, {
	__init__: function(edit, button) {
		this.__element__ = DOM.style({'position': 'absolute', 'zIndex': '100', 'visibility': 'hidden'}, DOM.DIV({'className': 'inputcalendar'},
					this.table = DOM.TABLE({'cellSpacing': '0'}, DOM.THEAD(null,
							DOM.TR({'className': 'monthrow'},
								DOM.TH({'align': 'right'}, this.btnDownMonth = DOM.style({'cursor': 'pointer'}, png.load('arrowleft.png', 6, 9))),
								DOM.TH({'colSpan': 3}, this.txtMonth = DOM.SPAN(null, 'January')),
								DOM.TH({'align': 'left'}, this.btnUpMonth = DOM.style({'cursor': 'pointer'}, png.load('arrowright.png', 6, 9))),
								DOM.TH({'colSpan': 2},
									this.btnDownYear = DOM.style({'marginRight': '5px', 'cursor': 'pointer'}, png.load('arrowleft.png', 6, 9)),
									this.txtYear = DOM.style({'marginRight': '5px'}, DOM.SPAN(null, '2009')),
									this.btnUpYear = DOM.style({'cursor': 'pointer'}, png.load('arrowright.png', 6, 9)))
							  ),
							DOM.TR({'className': 'headrow'},
								DOM.TH({'width': '14%'}, 'Mon'),
								DOM.TH({'width': '14%'}, 'Tue'),
								DOM.TH({'width': '14%'}, 'Wed'),
								DOM.TH({'width': '14%'}, 'Thu'),
								DOM.TH({'width': '14%'}, 'Fri'),
								DOM.TH({'width': '14%'}, 'Sat'),
								DOM.TH({'width': '14%'}, 'Sun')
								)
						),
						this.body = DOM.TBODY())
				));
		var pos = DOM.getPos(edit);
		DOM.place(this.__element__, pos.x, pos.y + DOM.getHeight(edit), 200, 200);
		DOM.style({'cursor': 'pointer'}, button);
		Event.observe(button, 'click', this, this.toggle);
		Event.observe(this.btnDownMonth, 'click', this, this.downMonth);
		Event.observe(this.btnUpMonth, 'click', this, this.upMonth);
		Event.observe(this.btnDownYear, 'click', this, this.downYear);
		Event.observe(this.btnUpYear, 'click', this, this.upYear);
		this.edit = edit;

		this.cells = [];
		var x, y, cell = 0;
		for(y = 0; y < 6; y++) {
			var row = DOM.TR();
			for(x = 0; x < 7; x++) {
				var td = DOM.style({'cursor': 'pointer'}, DOM.TD({'align': 'center'}));
				this.cells.push(td);
				DOM.addNodes(row, td);
				Event.observe(td, 'click', this, this.cellClickCB(cell));
				cell += 1;
			}
			DOM.addNodes(this.body, row);
		}
	},

	layout: function() {
		DOM.place(this.__element__, undefined, undefined, DOM.getWidth(this.table), DOM.getHeight(this.table));
	},

	cellClickCB: function(cell) {
		return function(evt) {
			this.cellClick(cell);
		};
	},

	cellClick: function(cell) {
		var clicked = new Date(this.tlDate);
		clicked.setDate(clicked.getDate() + cell);
		this.edit.value = clicked.getDate() + '/' + (clicked.getMonth() + 1) + '/' + clicked.getFullYear();
//		this.edit.value = '%s/%s/%s'.format(clicked.getDate(), clicked.getMonth() + 1, clicked.getFullYear());	NOTE: format not supported by ie6
		this.hide();
	},

	monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

	downMonth: function(evt) {
		var month = this.month - 1, year = this.year;
		if(month < 0) {
			month = 11;
			year -= 1;
		}
		this.set(month, year);
	},

	upMonth: function(evt) {
		var month = this.month + 1, year = this.year;
		if(month > 11) {
			month = 0;
			year += 1;
		}
		this.set(month, year);
	},

	downYear: function(evt) {
		this.set(this.month, this.year - 1);
	},

	upYear: function(evt) {
		this.set(this.month, this.year + 1);
	},

	compare: function(d1, d2) {
		return d1.getFullYear() == d2.getFullYear() && d1.getMonth() == d2.getMonth() && d1.getDate() == d2.getDate();
	},

	set: function(month, year) {
		/* set current = the first day of the month */
		var current = new Date(year, month, 1);

		this.month = month;
		this.year = year;
		DOM.setTxt(this.txtMonth, this.monthNames[month]);
		DOM.setTxt(this.txtYear, year);

		/* go backward until we hit a monday */
		while(current.getDay() != 1)
			current.setDate(current.getDate() - 1);

		this.tlDate = new Date(current);

		/* now current = the first monday on or before the beginning of the month */
		var cell = 0;
		for(cell = 0; cell < 42; cell++) {
			if(this.compare(current, this.selected))
				this.cells[cell].className = 'selectedday';
			else if(current.getMonth() == month)
				this.cells[cell].className = 'onmonth';
			else
				this.cells[cell].className = 'offmonth';
			DOM.setTxt(this.cells[cell], current.getDate());
			current.setDate(current.getDate() + 1);
		}
	},

	toggle: function() {
		if(this.visible)
			this.hide();
		else
			this.show();
	},

	show: function() {
		/* parse the date that's in our editbox, failing that display todays date */
		var parts = this.edit.value.split('/');
		var day = parseInt(parts[0]), month = parseInt(parts[1]) - 1, year = parseInt(parts[2]);
		var d = new Date(year, month, day);
		if(isNaN(d.getDay()))
			d = new Date();		/* use today for an invalid date */
		this.selected = d;
		this.set(d.getMonth(), d.getFullYear());
		this.layout();
		DOM.show(this.__element__, true);
		this.visible = true;
	},

	hide: function() {
		DOM.hide(this.__element__, true);
		this.visible = false;
	}
});


function validatereportschedule(parms) {
	schedulef.clearErrors();

	if(parms["units"].length == 0) {
		DOM.setTxt($("submitstatus"), "Please select at least 1 vehicle");
		return null;
	}

	// after hours report
	if(parms['startAM'] == 'AM' || parms['startAM'] == 'PM') {
		if(parms['startAM'] == 'PM')
			parms['starthour'] += 12;
		if(parms['endAM'] == 'PM')
			parms['endhour'] += 12;
		delete parms['startAM'];
		delete parms['endAM'];
	}

	if($('scheduleselecttype') && parms['scheduleselecttype'] != 'vehicle'){
		var types = parms['units'];
		parms['units'] = [];
		if(parms['scheduleselecttype'] == 'vehicletypes'){
			for(var i =0; i < gUnits.length; i++){
				if(types.indexOf(gUnits[i][4]) != -1){
					parms['units'].push(gUnits[i][0]);
				}
			}
		}
		else if(parms['scheduleselecttype'] == 'groups'){
			for(var i = 0; i < gGroups.length; i++){
				if(types.indexOf(String(gGroups[i][0])) != -1)
					parms['units'].push.apply(parms['units'], gGroups[i][4]);
			}
		}
	}
	if(parms['skipweekends'] && parms['frequency'] == 'week'){
		var weekday = parms['weekday']
		if(weekday != 'saturday' && weekday != 'sunday' && weekday != 'monday'){
			DOM.setTxt($('submitstatus'), 'To skip weekends the delivery day must be Monday or a weekend day');
			schedulef.setError('weekday');
			return null;
		}
	}
	delete parms['month'];
	delete parms['scheduleselecttype'];
	delete parms['reset'];
	parms['rid'] = gReportId;
	return parms;
}

function validatereport(parms) {
	delete parms['start.day'];
	delete parms['start.month'];
	delete parms['start.year'];
	delete parms['start.hour'];
	delete parms['start.minute'];
	delete parms['start.ampm'];
	parms['start'] = parseInt(getdate(f, 'start') / 1000);

	delete parms['end.day'];
	delete parms['end.month'];
	delete parms['end.year'];
	delete parms['end.hour'];
	delete parms['end.minute'];
	delete parms['end.ampm'];
	parms['end'] = parseInt(getdate(f, 'end') / 1000) + 86400;		// run to the end of 'end' day
// 	parms['session'] = Cookie.getRaw('_SID_');		/* correct, our problem was in jsc */
	if(parms['end'] - parms['start'] > 2851200) {		// 32 days
		DOM.setTxt($("submitstatus"), "Date range not valid. A maximum of 1 month can be selected.");
		return null;
	}
	var selecttype = $('selecttype').value;

	if(parms["units"].length == 0) {
		f.setError('units');
		if(selecttype == 'driver')
			DOM.setTxt($('submitstatus'), 'Please select at least 1 driver');
		else
			DOM.setTxt($("submitstatus"), "Please select at least 1 vehicle");
		return null;
	}

	// after hours report
	if(parms['startAM'] == 'AM' || parms['startAM'] == 'PM') {
		if(parms['startAM'] == 'PM')
			parms['starthour'] += 12;
		if(parms['endAM'] == 'PM')
			parms['endhour'] += 12;
		delete parms['startAM'];
		delete parms['endAM'];
	}

	if(selecttype != 'vehicle'){
		var types = parms['units'];
		parms['units'] = [];
		if(parms['selecttype'] == 'vehicletypes'){
			for(var i =0; i < gUnits.length; i++){
				if(types.indexOf(gUnits[i][4]) != -1){
					parms['units'].push(gUnits[i][0]);
				}
			}
		}
		else if(parms['selecttype'] == 'groups'){
			for(var i = 0; i < gGroups.length; i++){
				if(types.indexOf(String(gGroups[i][0])) != -1)
					parms['units'].push.apply(parms['units'], gGroups[i][4]);
			}
		}
		else if(parms['selecttype'] == 'driver'){
			parms['units'] = [];
			parms['drivers'] = types;
		}
	}

	if (parms['placeselect']) {
		if (parms['placeselect'] == 'placenames')
			delete parms['placetype'];
		delete parms['placeselect'];
	}

	delete parms['inplace'];
	delete parms['selecttype'];

	return parms;
}


function validatelocationreport(parms) {
// 	parms['session'] = Cookie.getRaw('_SID_');
	if(parms["units"].length == 0) {
		DOM.setTxt($("submitstatus"), "Please select at least 1 vehicle");
		return null;
	}

	if($('selecttype') && parms['selecttype'] != 'vehicle'){
		var types = parms['units'];
		parms['units'] = [];
		if(parms['selecttype'] == 'vehicletypes'){
			for(var i =0; i < gUnits.length; i++){
				if(types.indexOf(gUnits[i][4]) != -1){
					parms['units'].push(gUnits[i][0]);
				}
			}
		}
		else if(parms['selecttype'] == 'groups'){
			for(var i = 0; i < gGroups.length; i++){
				if(types.indexOf(String(gGroups[i][0])) != -1)
					parms['units'].push.apply(parms['units'], gGroups[i][4]);
			}
		}
	}

	delete parms['selecttype']
	delete parms['inplace']
	return parms;
}
function reportschedulesubmitted(data){
	if(data == 'null' || data == '')
		window.location = '/myaccount/';
	else if(data == 'false')
		DOM.setTxt($('submitstatus'), 'Error generating report, please try again later');
	else
		window.location = 'scheduledreports.html';
}

function placereportsubmitted(data) {
	if(data == 'null' || data == '')
		window.location = '/myaccount/';
	else if(data == 'false')
		setError($('submit'), 'Internal error');
	else
		window.location = 'reports.html';
}

function reportsubmitted(data) {
	if(data == 'null' || data == '')
		window.location = '/myaccount/';
	else if(data == 'false')
		DOM.setTxt($('submitstatus'), 'Error generating report, please try again later');
	else
		window.location = 'reports.html';

// 	tag = eval(data);
// 	server = tag.charAt(0);
// 	tag = tag.substring(1);
//
// 	if (pagename == 'export.html'){
// 		csv = true;
// 		inplace = false;
// 	}
// 	else{
// 	inplace = f.val('inplace');
// 	csv = f.val('csv');
// 	}
// 	window.location = 'generating.html?server=' + server + '&tag=' + tag + '&inplace=' + inplace + '&csv=' + csv;
//

// 	if(eval(data)) {
// 		/* all done, no need to hang around */
// 		if(pagename == 'export.html' || f.val('csv'))
// 			window.location = 'generatingcsv.html';
// 		else
// 			window.location = 'generating.html';
// 	}
// 	else
// 		DOM.setTxt($("submitstatus"), "");
}

function contactsubmitted(data) {
	$("form").style.display = "none";
	$("sent").style.display = "block";
}

function feedbackvalidate(parms) {
	if(! parms['message']) {
		DOM.setTxt($("submitstatus"), 'Please enter a message');
		return null;
	}
	parms['method'] = 'feedback';
	return parms;
}

function validemail(address){
	regex = /^.+@.+$/;		// For motivation, see: https://davidcel.is/posts/stop-validating-email-addresses-with-regex/
	return address.match(regex);
}

function contactvalidate(parms) {
	if(! parms['message']) {
		DOM.setTxt($("submitstatus"), 'Please enter a message');
		return null;
	}
	if(!validemail(parms['email'])){
		DOM.setTxt($("submitstatus"), 'Please supply a valid email address');
		return null;
	}
	if(! parms['email'] && ! parms['phone']) {
		DOM.setTxt($("submitstatus"), 'Please supply either a phone number or an email address');
		return null;
	}
	if (parms['reason']){
		parms['method'] = parms['reason'];
		delete parms['reason']
	}
	else
		parms['method'] = 'support';

	return parms;
}

function setDone(elem, redir) {
	var fa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
	var spanTxt = DOM.SPAN(null, "Done");
	DOM.removeChildren(elem);;
	DOM.addNodes(elem, fa, spanTxt);
	if (redir) {
		window.setTimeout(function () {
			if(typeof(redir) == 'function')
				redir();
			else
				window.location = redir;
		}.bind(this), 2500);
	}
}

function setError(elem, stat) {
	var fa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-exclamation'}, ''));
	var spanTxt = DOM.SPAN(null, "Failed (" + stat + ")");
	DOM.removeChildren(elem);
	DOM.addNodes(elem, fa, spanTxt);
}

function passwordsubmitted(data) {
	if(data == 'null') {
		window.location = '/myaccount/';
		return;
	}
	if(eval(data)) {
		var error_div = $('error_div');
		while (error_div.firstChild)
			error_div.removeChild(error_div.firstChild);
		error_div.className = 'success_div';
		DOM.addNodes(error_div, DOM.SPAN(null, 'Password successfully changed'));
		DOM.show(error_div);
		setDone(this.elStatus);
	}
	else {
		var errorList = DOM.UL({'id': 'errorUL'}, '');
		f.setError('oldpass');
		DOM.addNodes(errorList, DOM.LI(null, 'Authentication error - please supply the correct password'));
		DOM.addNodes($('error_content'), errorList);
		DOM.show($('error_div'));
		DOM.removeChildren(this.elStatus);
		DOM.addNodes(f.elStatus, DOM.SPAN(null, 'UPDATE PASSWORD'));
// 		DOM.setTxt($("submitstatus"), 'Authentication error - please supply the correct password');
	}
}

function resetpasswordsubmitted(data) {
	if(eval(data)) {
		var error_div = $('error_div');
		while (error_div.firstChild)
			error_div.removeChild(error_div.firstChild);
		error_div.className = 'success_div';
		DOM.addNodes(error_div, DOM.SPAN(null, 'Password has been reset. You may close this window.'));
		DOM.show(error_div);
		setDone(this.elStatus, '/myaccount/');
	}
	else {
		$('error_content').innerHTML = 'Error resetting password. Please try again.';
		DOM.show($('error_div'));
	}
}

function passwordvalidate(parms) {
	f.clearErrors();
	var failure = false;
	if ($('errorUL')) {
		DOM.hide($('error_div'));
		DOM.removeNodes($('error_content'), $('errorUL'));
	}
	var errorList = DOM.UL({'id': 'errorUL'}, '');
	if (!parms['newpass']) {
		DOM.addNodes(errorList, DOM.LI(null, 'New password must not be blank'));
		f.setError('newpass');
// 		DOM.setTxt($("submitstatus"), 'Please supply a new password');
		failure = true;
	}
	if (parms['newpass'].length < 6) {
		DOM.addNodes(errorList, DOM.LI(null, 'New password must be a minimum of 6 characters long'));
		f.setError('newpass');
// 		DOM.setTxt($("submitstatus"), 'Please supply a minmum 6 characters new password');
		failure = true;
	}
	if (parms['newpass'] != parms['reenterpass']) {
		DOM.addNodes(errorList, DOM.LI(null, 'New password and confirmation password do not match'));
		f.setError('reenterpass');
// 		DOM.setTxt($("submitstatus"), 'New password and reentered password do not match');
		failure = true;
	}
	if (failure) {
		DOM.show($('error_div'));
		DOM.addNodes($('error_content'), errorList);
		return null;
	}
	delete parms['reenterpass'];
	return parms;
}

function validateexternalconfig(parms){
	parms['id'] = vehicleid;
	return parms;
}

function validatesystechconfig(parms){
	parms['id'] = vehicleid;
	if (Number(parms['lowertemp']) > Number(parms['uppertemp'])){
		DOM.setTxt($('submitstatus'), 'Invalid temperature range');
		return null;
	}
	return parms;
}

function validatepivotelconfig(parms){
	parms['id'] = vehicleid;
	if (parms['DI2'] == 'PTO')
		parms['Epanic'] = false;

	return parms;
}

function validateconfiguration(parms) {
	parms['id'] = vehicleid;
	parms['primary'] = parms['primary'].replace(/ /g, '');
	parms['secondary'] = parms['secondary'].replace(/ /g, '');
	/* check whether someone is trying to configure the unit to notify itself */
	if(parms['primary'] == phonenumber) {
		DOM.setTxt($("submitstatus"), 'Primary phone number set to unit phone number! Please enter your mobile number here.');
		return null;
	}
	if(parms['secondary'] == phonenumber) {
		DOM.setTxt($("submitstatus"), 'Secondary phone number set to unit phone number! Please enter your mobile number here.');
		return null;
	}
	if(configdata['temperature'] == 'on'){
		if (Number(parms['lowertemp2']) > Number(parms['uppertemp2'])){
			DOM.setTxt($('submitstatus'), 'Invalid temperature range');
			return null;
		}
	}
	return parms;
}

function validateAtrackConfig(parms) {
	parms['id'] = vehicleid;
	if(configdata['temperature'] == 'on'){
		if (Number(parms['lowertemp2']) > Number(parms['uppertemp2'])){
			DOM.setTxt($('submitstatus'), 'Invalid temperature range');
			return null;
		}
	}
	return parms;
}

function datevalidator(date, maxYear, minYear){
	var error = false;
	var currentDate = new Date();
	minYear = minYear == null ? 2 : minYear;
	maxYear = maxYear == null ? 2 : maxYear;
	maxYear = currentDate.getFullYear() + maxYear;
	minYear = currentDate.getFullYear() - minYear;
	re = /^(\d{1,2})[^\d\w](\d{1,2})[^\d\w](\d{2,4})$/;
	if(matched = date.match(re)){
		var validDate = new Date(matched[3], matched[2] - 1, matched[1]);
		if(matched[3].length == 2)
			matched[3] = '20' + matched[3];
		if(matched[2] > 12)
			return [false, 'Invalid month'];
		else if (matched[1] > 31 || (validDate.getDate() != matched[1] || validDate.getMonth() != matched[2] - 1))
			return [false, 'Invalid day of month'];
		else if(matched[3] < minYear || matched[3] > maxYear)
			return [false, 'Invalid year'];
	}
	else
		return [false, 'Invalid date value'];
	return [true, matched[1] + '/' + matched[2] + '/' + matched[3], validDate];
}


function validatedetails(parms) {
	error = false;
	parms['id'] = vehicleid;
	return parms;
}


function detailssubmitted(data) {
	if (data == 'null') {
		window.location = '/myaccount/';
		return;
	}
	data = eval(data);
	if (data == true)
		window.location = '/myaccount/editvehicles.html';
	else {
		if (data == '')
			data = 'Error saving details';
		this.failed(data);
	}
}


function validateschedule(parms){
	this.clearErrors()
	if (parms['odo']){
		if(isNaN(parms['odo'])){
			this.setError('odo');
			DOM.setTxt($("submitschedule"), 'Odometer override MUST be numerical');
			return null;
		}
		if(parms['odo'] != '')
			parms['odo'] = parseInt(parms['odo']);
	}

	var currentOdo = 0;
	if($('odo'))
		currentOdo =  $('odo').value != '' ? $('odo').value : odoreading;

	var description = '';
	currentDate = new Date();
	currentCDate = new CDate();
	if (parms['wofdue']){
		if(parms['Ewof']){
			validator = datevalidator(parms['wofdue'], 6, 0);
			if(validator[0]){
				var wof = validator[1].split('/')
				CDateWOF = new CDate(wof[2], wof[1], wof[0])
				if(currentCDate.cmp(CDateWOF) == -1)
					validator = [false, 'Invalid year']
			}
		}
		else
			validator = datevalidator(parms['wofdue'], 6, 0);
		if(!validator[0]){
			this.setError('wofdue');
			description = ''
			if(validator[1] == 'Invalid year'){
				description = ' - date cannot be in the past'
			}
			DOM.setTxt($("submitschedule"), validator[1] + description);
			return null;
		}
		else
			parms['wofdue'] = validator[1];
	}
	if (parms['wof.day']){
		parms['wofdue'] = parms['wof.day'] + '/' + ($('wof.month').selectedIndex + 1).toString() + '/' + parms['wof.year'];
		delete parms['wof.day'];
		delete parms['wof.month'];
		delete parms['wof.year'];
		if(parms['Ewof']){
			validator = datevalidator(parms['wofdue'], 6, 0, false);
			if(validator[0]){
				var wof = validator[1].split('/')
				CDateWof = new CDate(wof[2], wof[1], wof[0])
				if(currentCDate.cmp(CDateWof) == -1)
					validator = [false, 'Invalid year']
			}
		}
		else
			validator = datevalidator(parms['wofdue'], 6, 0, false);

		if(!validator[0]){
			this.setError('wof.year');
			this.setError('wof.month');
			this.setError('wof.day');
			var description = ' - date must be in the format "dd/mm/YYYY or "dd/mm/YY"'
			if (validator[1] == 'Invalid year'){
				var description = ' - date cannot be in the past'
			}
			DOM.setTxt($("submitschedule"), validator[1] + description);
			return null;
		}
		else
			parms['wofdue'] = validator[1];
	}
	if (parms['rego.day']){
		parms['regodue'] = parms['rego.day'] + '/' + ($('rego.month').selectedIndex + 1).toString() + '/' + parms['rego.year'];
		delete parms['rego.day'];
		delete parms['rego.month'];
		delete parms['rego.year'];
		if(parms['Erego']){
			validator = datevalidator(parms['regodue'], 6, 0, false);
			if(validator[0]){
				var regdue = validator[1].split('/')
				CDateReg = new CDate(regdue[2], regdue[1], regdue[0])
				if(currentCDate.cmp(CDateReg) == -1)
					validator = [false, 'Invalid year']
			}
		}
		else
			validator = datevalidator(parms['regodue'], 6, 0, false);

		if(!validator[0]){
			this.setError('rego.day');
			this.setError('rego.month');
			this.setError('rego.year');
			description = '';
			if (validator[1] == 'Invalid year') {
				description = ' - date cannot be in the past';
			}
			DOM.setTxt($("submitschedule"), validator[1] + description);
			return null;
		}
		else
			parms['regodue'] = validator[1];
	}
	if (parms['serviceduetime']){
		validator = datevalidator(parms['serviceduetime'], 6, 0);
		if(validator[0]){
			var wof = validator[1].split('/')
			CDateReg = new CDate(wof[2], wof[1], wof[0])
			if(currentCDate.cmp(CDateReg) == -1)
				validator = [false, 'Invalid year']
		}

		if(!validator[0]){
			this.setError('servicetime');
			var description = ''
			if (validator[1] == 'Invalid year'){
				var description = ' - date cannot be in the past'
			}
			DOM.setTxt($("submitschedule"), validator[1] +  description);
			return null;
		}
		else
			parms['serviceduetime'] = validator[1];
	}

	if (parms['Eruc']){
		if (isNaN(parms['rucexpiry']) || parms['rucexpiry'] == ''){
			this.setError('rucexpiry');
			DOM.setTxt($("submitschedule"), 'RUC expiry mileage MUST be numerical');
			return null
		}
		if(Number(parms['rucexpiry']) < Number(currentOdo)){
			this.setError('rucexpiry');
			DOM.setTxt($("submitschedule"), 'Road user charges expiry mileage must not be less than the current Odometer reading, or override');
			return null;
		}
	}
	if(parms['EserviceMileage']){
		if (isNaN(parms['serviceduemileage']) || parms['serviceduemileage'] == ''){
			this.setError('servicemileage');
			DOM.setTxt($("submitschedule"), 'Service expiry mileage MUST be numerical');
			return null
		}
		if(Number(parms['serviceduemileage']) < Number(currentOdo)){
			this.setError('serviceduemileage');
			DOM.setTxt($("submitschedule"), 'Service expiry mileage must not be less than the current Odometer reading, or override');
			return null;
		}
	}

	parms['id'] = vehicleid;
	return parms;
}


function configurationsubmitted(data) {
	if(data == 'null') {
		window.location = '/myaccount/';
		return;
	}
	data = eval(data);
	if(data == true){
		if (typeof this.elStatus != 'undefined' && this.elStatus.type == 'submit'){
			setDone(this.elStatus, window.location.reload.bind(window.location));
		}
		else{
			DOM.setTxt(this.elStatus, 'Done');
			setTimeout(function(){
				DOM.setTxt(this.elStatus, '');
			}.bind(this), 5000);
		}
	}
	else {
		DOM.setTxt(this.elStatus, data);
	}
}

function validategroup(parms) {
	if (gGroupId == 'new')
		parms['gid'] = 0;
	else
		parms['gid'] = gGroupId;


	if (parms['name'] == '') {
		this.setError('name');
		DOM.setTxt($("submitstatus"), 'Please set a name for this vehicle group');
		return;
	}

	if (parms['selectto'].length == 0) {
		this.setError('selectto');
		DOM.setTxt($('submitstatus'), 'Please assign at least one vehicle to this group');
		return;
	}

	parms['assigned'] = parms['selectto'];
	delete parms['selectto'];


	return parms;
}

function accountvalidate(parms){
	var starthour = parms['starthour'] = parms['startAM'] == 'PM' ? parseInt(parms['starthour']) + 12 : parms['starthour'];
	var endhour = parms['endhour'] = parms['endAM'] == 'PM' ? parseInt(parms['endhour']) + 12 : parms['endhour'];
	var error = false;

	if(parms['primaryalertemail'] && !validemail(parms['primaryalertemail'])){
		DOM.setTxt($('submitstatus'), 'Please enter a valid email address');
		this.setError('primaryalertemail');
		return null;
	}

	if(parms['secondaryalertemail'] && !validemail(parms['secondaryalertemail'])){
		DOM.setTxt($('submitstatus'), 'Please enter a valid email address');
		this.setError('secondaryalertemail');
		return null;
	}

	if(parseInt(starthour) >= parseInt(endhour)){
		if(starthour == endhour){
			if (parseInt(parms['startmins']) >= pareseInt(parms['endmins']))
				error = true;
		}
		else
			error = true;
	}

	if (error){
		DOM.setTxt($("submitstatus"), 'End time must not precede start time');
		this.setError('endhour');
		this.setError('endmins');
		this.setError('endAM');
		return null;
	}

	delete parms['startAM'];
	delete parms['endAM'];
	delete parms['contactemail'];
	delete parms['name'];
	delete parms['contactmobile'];
	delete parms['contactname'];
	delete parms['contactphone'];
	return parms;
}

function loginsubmitted(data) {
	if(eval(data)){
		window.location = '/myaccount/';
	}
	else {
		DOM.setTxt($('loginsubmitstatus'), 'Bad username or password');
	}
}


function has(features, feature) {
	for(var i = 0; i < features.length; i++)
		if(features[i] == feature)
			return true;
	return false;
}

function checkcell(on) {
	if(on)
		return DOM.style({'textAlign': 'center'}, DOM.TD(null, png.load('tick.png', 16, 16)));
	else
		return DOM.TD(null);
}

function caps(e){
	evt = e;
	var keyCode=0;
	var shiftKey=false;
	var caplock = false;
	keyCode = evt.charCode || evt.keyCode;

	shiftKey = evt.shiftKey;
	if ( (keyCode >= 65 && keyCode <= 90 ) && !shiftKey )
		caplock = true;
	else if ( ( keyCode >= 97 && keyCode <= 122 ) && shiftKey )
		caplock = true;
	if (caplock)
		DOM.setTxt($("badpasssubmitstatus"),'Please check your CAPS LOCK key');
	else
		DOM.setTxt($("badpasssubmitstatus"), '');
}

function cellInboundMessage(row, idx, cell) {
	if(row != null) {
		if(row[0] == 'intxt')
			cell.firstChild.data = '>';
		else
			cell.firstChild.data = '<';
	}
	else
		cell.firstChild.data = '';
}

function formatTime(timestamp){
	if (timestamp == 0)
		return '';
	var time = CDateTime.fromTimestamp(timestamp);
	var date = time.date.strftime('%d/%m');
	var today = new Date();
	todayDay = today.getDate();
	todayMonth = today.getMonth() + 1;
	todayYear = today.getFullYear();
	if(time.date.day == todayDay && time.date.month == todayMonth && time.date.year == todayYear)
		ret = time.time.strftime('%h:%M');
	else
		ret = date + '  '  + time.time.strftime('%h:%M');
	return ret
}

function cellFmtTime(row, idx, cell){
	if(row != null){
		var dt = formatTime(row[1]);
		cell.firstChild.data = dt;
	}
	else
		cell.firstChild.data = '';
}

function inReadMessages(msgId){
	var hasread = Math.max(gx4ReadMessages.indexOf(msgId), gx3ReadMessages.indexOf(msgId))
	return hasread >= 0
}
function msgRead(rowdata, row){
	if(rowdata){
		var fontweight = 'normal';
		if((rowdata[0] == 'intxt' && ! inReadMessages(rowdata[4])) || (rowdata[0] != 'intxt' && !rowdata[6])){
			fontweight = 'bold'
		}
		for(var col = 0; col < row.length; col++)
			row[col].style.fontWeight = fontweight;
	}
}

function displayMsg(rowdata){
	var types = {'outtxt': 'Outbound Text', 'outstop': 'Outbound Stop', 'intxt': 'Incoming Text'};

	if(rowdata){
		var displayed = '';
		DOM.show($('messagedetails'));

		DOM.setTxt($('messagebody'), rowdata[3]);
		if(rowdata[6] == 0 || rowdata[6] == null)
			DOM.setTxt($('msgReceived'), '');
		else
			DOM.setTxt($('msgReceived'),  formatTime(rowdata[6]));
		DOM.setTxt($('msgSent'), formatTime(rowdata[1]));
		DOM.setTxt($('Vehicle'), rowdata[2]);
		DOM.setTxt($('msgType'), types[rowdata[0]]);
		DOM.hide($('txtAddress'));
		DOM.hide($('Address'))
		if(rowdata[0] == 'outstop'){
			DOM.setTxt($('Address'), rowdata[7]);
			DOM.show($('txtAddress'));
			DOM.show($('Address'));
		}
		if(rowdata[0] == 'intxt' && ! inReadMessages(rowdata[4])){
			Ajax.get('/myaccount/readmessage?msgid=' + rowdata[4], function(){});
			gx4ReadMessages.push(rowdata[4]);
		}
	}
	else
		DOM.hide($('messagedetails'));
}


function formatLastAccess(la) {
	if(la === 0 || la === '0')
		return '';
	if(isNaN(parseInt(la)))
		return 'None';
	else {
		var d = CDateTime.fromTimestamp(la);
		return d.strffuzzy();
	}
}

function setDI2(){
	var DI2 = $('DI2').value;

	if($('DI2').value == 'panic'){
		DOM.show($('lblEpanic'), true);
		DOM.setTxt($('thEmail'), 'EMAIL');
	}
	else{
		DOM.hide($('lblEpanic'), true);
		DOM.setTxt($('thEmail'), '');
	}
}
function setprobetype(){
	var probetype = $('probetype').value;
	if (probetype != '0'){
		DOM.show($('temprange'));
		if(probetype == '-40-60')
			startValue = -40;
		else
			startValue = 0;

		$('uppertemp').options.length  = 0;
		$('lowertemp').options.length = 0;

		for(var i = 0; i <= 100; i +=2){
			var temp = String(startValue + i) + ' &#186;';
			var opt = DOM.OPTION({'value': startValue + i}, "");
			opt.innerHTML = temp;
			DOM.addNodes($('lowertemp'), opt);
		}
		for(var i = 100; i >= 0; i -= 2){
			var temp = String(startValue + i) + ' &#186;';
			var opt = DOM.OPTION({'value': startValue + i}, "");
			opt.innerHTML = temp;
			DOM.addNodes($('uppertemp'), opt);
		}
	}
	else{
		DOM.hide($('temprange'));
	}

}

function toggleSwitchFocus(elem, isOnFocus) {
	toswitch = elem.parentElement;
	if (!isOnFocus)
		toswitch.className = 'toggleSwitch';
	else
		toswitch.className = 'toggleSwitch toggleSwitchFocus';
}


function setAddress(lat, lon, element){
	var pt = new Proj4js.Point(lon, lat);
	var source = new Proj4js.Proj('EPSG:4326');
	var dest = new Proj4js.Proj('EPSG:3857');
	Proj4js.transform(source, dest, pt);
	var northing = pt.y;
	var easting = pt.x;

	Ajax.get("resolveaddress?northing=" + parseInt(northing) + "&easting=" + parseInt(easting) + "&srid=3857", function(data, status){
		if(status == 200){
			while($(element).hasChildNodes() ){
				$(element).removeChild($(element).lastChild);
			}
			var res = eval(data);
			var number = res[2], street = res[3], suburb = res[4];

			if (street != '') {
				address = DOM.I({'className': 'icon-map-marker'}, '');
				DOM.addNodes($(element), address, ' ' + suburb);
			}
		}
	});
}

function typeImage(vehicletype) {
		vType = 'car'
		if(vehicletype == 'Motorbike')
			vType = 'bike';
		else if(vehicletype == 'Truck')
			vType = 'truck';
		else if(vehicletype == 'Asset')
			vType = 'asset';
		else if(vehicletype == 'Boat')
			vType = 'boat';
		else if(vehicletype == 'Plane')
			vType = 'plane';
		else if(vehicletype == 'Van')
			vType = 'van';
			else if(vehicletype == 'Ute')
			vType = 'ute';
		else if(vehicletype == 'Bus')
			vType = 'bus';
		else if(vehicletype == 'Construction')
			vType = 'construction';
		else if(vehicletype == 'Portable')
			vType = 'portable';
		else if(vehicletype == 'Pedestrian')
			vType = 'pedestrian';
		else if(vehicletype == 'Phone')
			vType = 'phone';
		return 'vehicles/' + vType + '.png';
}

function onSelectType(){
	var selecttype = $('selecttype').value;
	var unitselect = $('units');
	while(unitselect.hasChildNodes()){
		DOM.removeNodes(unitselect, unitselect.lastChild);
	}
	if (selecttype == 'vehicle'){
		for(var i = 0; i < gUnits.length; i++){
			DOM.addNodes(unitselect, DOM.OPTION({'value': gUnits[i][0]}, gUnits[i][1]));
		}
		unitselect.size = Math.min(10, gUnits.length);
	}
	else if(selecttype == 'vehicletypes'){
		var types = [];
		for(var i = 0; i < gUnits.length; i++){
			if(types.indexOf(gUnits[i][4]) == -1)
				types.push(gUnits[i][4]);
		}
		types.sort();
		for(var i = 0; i < types.length; i++){
			DOM.addNodes(unitselect, DOM.OPTION({'value': types[i]}, types[i]));
		}
		unitselect.size = Math.min(unitselect.size, types.length);
	}
	else if(selecttype == 'groups'){
		for(var i = 0; i < gGroups.length; i++){
			DOM.addNodes(unitselect, DOM.OPTION({'value': gGroups[i][0]}, gGroups[i][1]));
		}
		unitselect.size = Math.min(10, gGroups.length);
	}
	else if(selecttype == 'driver') {
		for(var i = 0; i < gDrivers.length; i++) {
			DOM.addNodes(unitselect, DOM.OPTION({'value': gDrivers[i][0]}, gDrivers[i][1]));
		}
		unitselect.size = Math.min(10, gDrivers.length);
	}
}

function onPlaceType(){
	var selection = $('placeselect').value;
	if (selection == 'placetypes') {
		DOM.hide($('placename_fieldset'));
		DOM.show($('placetype_fieldset'));
	}
	else if (selection == 'placenames') {
		DOM.show($('placename_fieldset'));
		DOM.hide($('placetype_fieldset'));
	}
}

function onScheduleSelectType(){
	var selecttype = $('scheduleselecttype').value;
	var unitselect = $('units');
	while(unitselect.hasChildNodes()){
		DOM.removeNodes(unitselect, unitselect.lastChild);
	}
	if (selecttype == 'vehicle'){
		for(var i = 0; i < gUnits.length; i++){
			DOM.addNodes(unitselect, DOM.OPTION({'value': gUnits[i][0]}, gUnits[i][1]));
		}
		unitselect.size = Math.min(10, gUnits.length);
	}
	else if(selecttype == 'vehicletypes'){
		var types = [];
		for(var i = 0; i < gUnits.length; i++){
			if(types.indexOf(gUnits[i][4]) == -1)
				types.push(gUnits[i][4]);
		}
		types.sort();
		for(var i = 0; i < types.length; i++){
			DOM.addNodes(unitselect, DOM.OPTION({'value': types[i]}, types[i]));
		}
		unitselect.size = Math.min(unitselect.size, types.length);
	}
	else if(selecttype == 'groups'){
		for(var i = 0; i < gGroups.length; i++){
			DOM.addNodes(unitselect, DOM.OPTION({'value': gGroups[i][0]}, gGroups[i][1]));
		}
		unitselect.size = Math.min(10, gGroups.length);
	}
}

function onScheduleFrequency(){
	var frequency = $('frequency').value;
	if(frequency == 'month'){
		DOM.show($('end_month'));
		DOM.hide($('skipweekenddiv'));
		DOM.hide($('end_day'));
	}
	else if(frequency == 'week'){
		DOM.hide($('end_month'));
		DOM.show($('end_day'));
		DOM.show($('skipweekenddiv'));
	}
	else{
		DOM.hide($('end_month'));
		DOM.hide($('end_day'));
		DOM.show($('skipweekenddiv'));
	}
}

function isSnitchArmada(boardtype){
	if (boardtype != null){
		if(boardtype.indexOf('tb') == 0 || boardtype.indexOf('sa') == 0 || boardtype == 'proto' || boardtype == 'bluev1' || boardtype == 'armadav1')
			return true;
	}
	return false;
}

function updateSchedulePoll(){
	Ajax.get('scheduledreports?id=' + gUserId, function(data, status){
		if(status == 200){
			var reports = unrepr(data);
			for (var i = 0; i < reports.length; i++){
				var report = reports[i];
				var reportdata = unrepr(gReports[i][1]);

				trElem = $('tr-' + report[0]);
				if(trElem == null){
					var frequency = gReports[i][8];
					if(frequency == 'day'){
						frequency = 'Daily ';
						frequency += gReports[i][10].length.toString() + ' days a Week';
					}
					else if(frequency == 'month'){
						var ord = getOrdinal(gReports[i][2]);
						frequency = DOM.SPAN(null, 'Monthly on the ' + gReports[i][2], DOM.SUP(null, ord));
					}
					else{
						var weekday = gReports[i][10][0];
						frequency = 'Weekly on ' + weekday[0].toUpperCase() + weekday.slice(1)
					}
					var nextrun = ''
					if(gReports[i][7] != null){
						nextrunTs = CDateTime.fromTimestamp(gReports[i][7]);
						nextrun = nextrunTs.date.strftime('%d/%m/%Y');
					}
					if(gReports[i][2] != null)
						frequency == 'Monthly';
					var unitslen = Object.keys(reportdata['units']).length.toString();
					if('fmt' in report)
						format = report['fmt'];
					else if('format' in report)
						format = report['format'];
					var formatImg = imgpng.load('doctypes/' + format + '.png', 28, 28);

					trElem = DOM.TR({'id': 'tr-' + report[0], 'className':'editLine'}, DOM.TD(null, DOM.style({'marginRight': '5px', 'verticalAlign': 'middle'}, imgpng.load('title/' + report['type'] + '.png', 24, 24)), ' ' + reportTypes[report['type']]), DOM.TD({'id': 'nextrun-' + report[0], 'align': 'center'}, nextrun), DOM.TD({'align': 'center'}, frequency), DOM.TD({'align': 'center'}, unitslen), DOM.TD({'align': 'center'}, formatImg), DOM.TD({'align': 'center'}, gReports[i][6]), DOM.TD({'id': 'status-' + gReports[i][0], 'align': 'center'}, DOM.A({'href':'scheduledreport.html?id=' + gReports[i][0]}, 'Edit / Delete')));

					gTable.addRow();
				}
				else{
					var nextrun = ''
					if(report[7] != null){
						nextrunTs = CDateTime.fromTimestamp(report[7]);
						nextrun = nextrunTs.date.strftime('%d/%m/%Y');
					}
					DOM.setTxt($('nextrun-' + report[0]), nextrun);
				}
			}
		}
	});
}

function updateReportPoll(){
	dt = new Date().getTime() / 1000;
	if(dt > gUrlExpiry)
		Ajax.get('runningreports', updateReportUrl);
	else if(gReportUrl != '') {
		window.reportupdate = reportupdate;
		JsonP.get(gReportUrl + '&jsonp=reportupdate', reportupdate);
	}
}

function updateReportUrl(data, status){
	if(status == 200) {
		reportdata = unrepr(data);
		gReportUrl = reportdata['reporturl'];
		gUrlExpiry = new Date().getTime() / 1000 + (60 * 5);
		if(!gTable)
			updateReportPoll();
	}
}

function reportupdate(data){
	var reports = data;
// // 	t = new Table("pendingreports", '/static/sort_asc.gif', '/static/sort_desc.gif');
	if(gTable)
		tbl = gTable;
	else
		tbl = new Table("reports", '/static/sort_asc.gif', '/static/sort_desc.gif', 4, false, [0]);

	if(tbl.data.length == 0 && reports.length == 0) {
		DOM.hide($('delreport'));
		DOM.hide($('contentRight'));
		DOM.show($('noreports'));
	}
	else {
		DOM.show($('delreport'));
		DOM.hide($('loadingDiv'));
	}
	for (var i = 0; i < reports.length; i++) {
		report = reports[i];
		if(report['jobid'] in gReports){
			if(report['status'] != gReports[report['jobid']]['status'] || report['jobtype'] != gReports[report['jobid']]['jobtype']){
				//update me
				var completed = report['completed'];
				var tcompleted = ''
				if(report['jobtype'] == 'completed'){
					tcompleted = CDateTime.fromTimestamp(completed);
					tcompleted = tcompleted.date.strftime('%d/%m/%Y') + ' ' + tcompleted.time.strftime('%h:%M');
				}
				var status = '';
				if(report['jobtype'] != 'completed'){
					if(report['status'] == 100)
						status = 'Generating';
					else if(report['status'] == 0)
						status = 'Pending';
					else
						status = report['status'].toString() + '% Complete';
				}
				DOM.setTxt($('status-' + report['jobid']), status);
				if(gReports[report['jobid']]['jobtype'] == 'pending' && report['jobtype'] == 'completed'){
					if(report['status'] == 'SUCCESS')
						var link = DOM.A({'target': '_blank', 'href': report['url']}, DOM.I({'className': 'icon-cloud-download'}, ''), DOM.style({'marginLeft': '5px'}, DOM.SPAN(null, 'Download')));
					else
						var link = DOM.style({'color': '#a93333'}, DOM.SPAN(null, ' Failure'));
					DOM.addNodes($('status-' + report['jobid']), link);
					for(var i = 0; i < gTable.data.length; i++){
						if(gTable.data[i][1].chkbox.id == report['jobid']){
							gTable.data[i][1].chkbox.disabled = false;
							DOM.show(gTable.data[i][1].chkbox, true);
							DOM.show(gTable.data[i][1].chkboxlabel, true);
							gTable.data[i][1].chkbox.className = 'standardCheck';
						}
					}
				}
				DOM.setTxt($('tcompleted-' + report['jobid']), tcompleted);
			}
			gReports[report['jobid']] = report;
		}
		else{
			gReports[report['jobid']] = report;
			var starttime = CDateTime.fromTimestamp(report['job']['starttime']);
			var endtime = CDateTime.fromTimestamp(report['job']['endtime']);

			var completed = report['completed'];
			var tcompleted = '';
			if(report['jobtype'] == 'completed') {
				tcompleted = CDateTime.fromTimestamp(completed);
				tcompleted = tcompleted.date.strftime('%d/%m/%Y') + ' ' + tcompleted.time.strftime('%h:%M');
			}
			var received = CDateTime.fromTimestamp(report['received']);
// 			var tcompleted = CDateTime.fromTimestamp(completed);
			var status = '';
			var hiding = false;
			if (report['jobtype'] == 'completed') {
				if (report['status'] == 'SUCCESS')
					status = DOM.A({'align': 'center', 'target': '_blank', 'href': report['url']}, DOM.I({'className': 'icon-cloud-download'}, ''), DOM.style({'marginLeft': '5px'}, DOM.SPAN(null, 'Download')));
				else
					status = DOM.style({'color': '#a93333'}, DOM.SPAN(null, ' Failure'));
			}
			else {
				hiding = true;
				if (report['status'] != 0) {
					if (report['status'] == 100)
						status = 'Generating';
					else
						status = report['status'].toString() + '% Complete';
				}
				else
					status = 'Pending';
			}
			var format = 'pdf'
			if('fmt' in report['job'])
				format = report['job']['fmt']
			else if('format' in report['job'])
				format = report['job']['format']

			var formatImg = imgpng.load('doctypes/' + format + '.png', 28, 28);

			var unitslen = Object.keys(report['job']['units']).length.toString();
			var chkbox;
			var chkboxlabel;
			var tr = DOM.TR({'id': 'tr-' + report['jobid'], 'className': 'editLine'}, DOM.style({'position': 'relative', 'textAlign': 'center', 'padding': '15px 0'}, DOM.TD(null, chkbox = DOM.INPUT({'id': report['jobid'], 'type': 'checkbox', 'className': 'standardCheck'}), chkboxlabel = DOM.LABEL({'htmlFor': report['jobid']}))), DOM.TD(null, DOM.style({'marginRight': '5px', 'verticalAlign': 'middle'}, imgpng.load('title/' + report['job']['type'] + '.png', 24, 24)), ' ' + reportTypes[report['job']['type']]), DOM.TD({'align': 'center'}, starttime.date.strftime('%d/%m/%Y')), DOM.TD({'align': 'center'}, endtime.date.strftime('%d/%m/%Y')), DOM.TD(null, received.date.strftime('%d/%m/%Y') + ' ' + received.time.strftime('%h:%M')), DOM.TD({'align': 'center', 'id': 'tcompleted-' + report['jobid']}, tcompleted), DOM.TD({'align': 'center'}, unitslen), DOM.TD({'align': 'center'}, formatImg), DOM.TD({'id': 'status-' + report['jobid'], 'align': 'center'}, status));
			tr.chkbox = chkbox;
			tr.chkboxlabel = chkboxlabel;
			if (hiding) {
				tr.chkbox.disabled = true;
				DOM.hide(tr.chkboxlabel, true);
				DOM.hide(tr.chkbox, true);
			}

			row = [' ', report['job']['type'], report['job']['starttime'], report['job']['endtime'], report['received'], report['jobtype'] == 'completed' ?  completed : 0, unitslen, report['job']['fmt']];

			tbl.addRow(row, tr);
			if(Browser.isIEPre9)
				Event.observe(chkbox, 'click', window, updateReportSelect);
			else
				Event.observe(chkbox, 'change', window, updateReportSelect);
		}
	}

	tbl.populate();
	tbl.linkHeaders();
	tbl.sort();
	gTable = tbl;
// 	tbl.model.sort(3, tbl.ascending);
}

function updateReportSelect(){
	var allTrue = true;
	var allFalse = true;
	for(var i = 0; i < gTable.data.length; i++){
		allTrue = gTable.data[i][1].chkbox.checked == true && allTrue;
		allFalse = gTable.data[i][1].chkbox.checked == false && allFalse;
	}
	if(allTrue || allFalse){
		$("selall").checked = allTrue;
		$("selallfor").className = '';

		$("delreport").className = allFalse ? 'disabled' : 'enabled'
	}
	else{
		$("delreport").className = 'enabled';
		$("selallfor").className = 'tristate';
	}
}
function selectAllreports(select){
	if(gTable.data.length == 0)
		$("selallfor").className = 'disabled';
	else{
		$("selallfor").className = '';
		if(select){
			$('delreport').className = 'enabled'
			for(var i = 0; i < gTable.data.length; i++){
				if(gTable.data[i][1].chkbox.disabled == false){
					gTable.data[i][1].chkbox.checked = true;
				}
			}
		}
		else{
			$('delreport').className = 'disabled'
			for(var i = 0; i < gTable.data.length; i++){
				gTable.data[i][1].chkbox.checked = false;
			}
		}
	}
}

function getSelectedJobid(){
	var jobids = '';
	for(var i = 0; i < gTable.data.length; i++){
		if(gTable.data[i][1].chkbox.checked === true)
			jobids +=String(gTable.data[i][1].chkbox.id) + ',';
	}
	return jobids;
}

function findFirstElem(elem, type) {
	if (typeof type == 'undefined')
		type = 'TR'

	if (elem.nodeName == type)
		return elem
	else
		return findFirstElem(elem.parentElement, type);
}

function addTrans(elem, removePar) {
	elem.style.maxHeight = "0px";
	if (elem.className.indexOf('trans_hidden') == -1)
		elem.className += ' trans_hidden';
	if(removePar){
		var par = findFirstElem(elem, 'FIELDSET');
		par.style.padding = 0;
	}
}

function removeTrans(elem, addPar) {
	DOM.show(elem);
	elem.style.maxHeight = "500px";
	var idx = elem.className.indexOf('trans_hidden')
	if (idx != -1)
		elem.className = elem.className.substring(0, idx - 1);
	if (addPar){
		var par = findFirstElem(elem, 'FIELDSET');
		par.style.padding = '';
	}

}

function init() {
	if(arguments.callee.done)
		return;
	arguments.callee.done = true;
	var parts = window.location.pathname.split('\/');
	pagename = parts[parts.length - 1];
	if($('mLink')){
		var messages = new lightBox('Messaging', 'mLink', '/myaccount/messaging.html', '', this);
	}
	var toggles = document.getElementsByClassName('toggleSwitch');
	if(!Browser.isIEPre9){
		for (var i = 0; i < toggles.length; i++){
			Event.observe(toggles[i], 'click', window, function(elem){
				return function(evt){
					var target = (evt.currentTarget) ? evt.currentTarget : evt.srcElement;
					Event.stop(evt);
					if(target.tagName != 'LABEL' && target.className.indexOf('disabledSwitch') == -1)
						elem.children[0].checked = !elem.children[0].checked;
				};
			}(toggles[i]));
		}
	}
/*	var fm = ($('loginform'));
	if(fm && !fm.jquery) {
		/* hook form +/
		if(! Cookie.enabled())
			DOM.setTxt($("loginsubmitstatus"), "Please enable cookies to login to the site");
	}*/
	if(pagename == '' || pagename == 'index.html'){
		$('loginWin').style.display = 'block';
		$('fade').style.display = 'block';
		loggingform = new Form("loginform", "/myaccount/login", null, $('loginsubmitstatus'), false, true);
		loggingform.submitted = loginsubmitted;
		var forgotpass = $('forgotpassword')
		if(forgotpass && !forgotpass.jquery){
			forgotform = new Form("forgotpassword", "/myaccount/forgotpassword", null, $('forgotsubmitstatus'));
			forgotform.validate = function(parms){
				for(parm in parms){
					if(parm != 'username')
						delete parms[parm];
				}
				return parms;
			}
			forgotform.submitted = function(data) {
				if(eval(data)) {
					DOM.hide($('forgot1'));
					$('sent').style.display = 'block';
				}
				else
					DOM.setTxt($("submitstatus"), 'Invalid username');
			};
		}
	}
	else if(pagename == 'mileagereport.html' || pagename == 'stopsreport.html' || pagename == 'auxreport.html' || pagename == 'export.html'
			|| pagename == 'activityreport.html' || pagename == 'incidentreport.html' || pagename == 'rucreport.html' || pagename == 'deliveryreport.html'
			|| pagename == 'timebyplacereport.html' || pagename == 'mileagebyplacereport.html' || pagename == 'timeonsitereport.html' || pagename == 'dailysummaryreport.html'
			|| pagename == 'messagingreport.html' || pagename == 'afterhoursreport.html' || pagename == 'ontimereport.html' || pagename == 'safetyreport.html') {
		f = new Form("report", '/myaccount/' + pagename.split('.')[0], null, $('submit'));

		f.validate = validatereport;
		f.submitted = placereportsubmitted;
		fillyears(f, 'start');
		fillyears(f, 'end');
		var d = new Date();
		filldate(f, 'end', d);
		d.setDate(d.getDate() - 7);
		filldate(f, 'start', d);

		function setAMPM(evt) {
			target = (evt.currentTarget) ? evt.currentTarget : evt.srcElement;
			elem = target.name.split('.')[0];
			if (f.element(elem + '.ampm').value == 'PM') {
				if (f.element(elem + '.hour').selectedIndex == 11) {
					f.element(elem + '.hour').selectedIndex -= 1;
					if (f.element(elem + '.minute').selectedIndex == 0)
						f.element(elem + '.minute').selectedIndex = 59;
				}
				f.element(elem + '.hour')[11].disabled = true;
			}
			else
				f.element(elem + '.hour')[11].disabled = false;
		}

		if (f.element('filterstart.hour')) {
			f.set('filterstart.hour', 8);
			f.set('filterstart.minute', 0);
			f.set('filterstart.ampm', 'AM');
			f.set('filterend.hour', 5);
			f.set('filterend.minute', 0);
			f.set('filterend.ampm', 'PM');
			Event.observe(f.element('filterstart.ampm'), 'change', f, setAMPM.bind(f));
			Event.observe(f.element('filterend.ampm'), 'change', f, setAMPM.bind(f));
			Event.observe($('filter'), 'change', window, function (evt) {
				if ($('filter').checked) {
					removeTrans($('filterend_fieldset'));
					removeTrans($('filterstart_fieldset'));
					removeTrans($('invert_fieldset'));
				}
				else {
					addTrans($('filterend_fieldset'));
					addTrans($('filterstart_fieldset'));
					addTrans($('invert_fieldset'));
				}
			})
		}
		if(pagename == 'rucreport.html')
			f.set('mindist', 1000);
		else if(pagename != 'locationreport.html' && pagename != 'export.html' && pagename != 'auxreport.html' && pagename != 'incidentreport.html' && pagename != 'timeonsitereport.html' && pagename != 'deliveryreport.html' && pagename != 'ontimereport.html')
			f.set('mindist', 10);

		if(pagename != 'export.html' && pagename != 'safetyreport.html' && pagename != 'incidentreport.html' && pagename != 'rucreport.html' && pagename != 'deliveryreport.html' && pagename != 'ontimereport.html')
			f.set('mintime', 120);
		if(pagename == 'timeonsitereport.html')
			f.set('placetype', 'customer');
		else if(pagename == 'deliveryreport.html'){
			f.set('mintime', 180);
			f.set('mindist', 20);
			f.set('placetype', 'base');
		}

		f.selectall('units');
		f.set('skipblank', true);
		f.set('timezone', Timezone.calculate());
		if($('selecttype'))
			Event.observe($('selecttype'), 'change',  window, onSelectType);

		if ($('placeselect'))
			Event.observe($('placeselect'), 'change', window, onPlaceType);
	}
	else if(pagename == 'reports.html'){
		Ajax.get('runningreports', updateReportUrl);
		window.setInterval(updateReportPoll,  10000);

		Event.observe($('selall'), 'click', window, function(evt){
			if($('selall').checked){
				selectAllreports(true);
			}
			else{
				selectAllreports(false);
			};
		});

		Event.observe($('delreport'), 'click', window, function(evt){
			var jobids = getSelectedJobid();
			if (jobids != '' && $('delreport').className != 'disabled') {
				if (confirm("Are you sure you wish to delete these reports?")) {
					DOM.setTxt($("delreport"), "");
					var aweGear = DOM.style({'fontSize': '125%'}, DOM.I({'className': 'icon-spin icon-spinner' }, ""));
					DOM.addNodes($('delreport'), aweGear, ' DELETING...');
					Event.stop(evt);
					Ajax.get('deletereports?jobids=' + jobids, function (data, status) {
						if (status == 200) {
							if (data) {
								$('delreport').innerHTML = "";
								DOM.setTxt($('delreport'), 'DELETE SELECTED');
								$('selall').checked = false;
								var ids = jobids.split(',');
								for (var k = 0; k < ids.length; k++) {
									for (var i = gTable.data.length - 1; i >= 0; i--) {
										if (gTable.data[i][1].chkbox.id == ids[k]) {
											DOM.hide(gTable.data[i][1]);
											gTable.data.splice(i, 1);
										}
									}
								}
								$('delreport').className = 'disabled';
							}
							else{
								DOM.setTxt($("delstatus"), 'Error deleting reports.');
							}
						}
						else{
							DOM.setTxt($("delstatus"), 'Error deleting reports.');
						}
						gUrlExpiry = 0;
						updateReportPoll();
					});
				};
			}
		});
	}
	else if(pagename == 'locationreport.html' || pagename == 'vehiclestatusreport.html' || pagename == 'schedulingreport.html') {
		f = new Form("report", '/myaccount/' + pagename.split('.')[0], null, $('submit'));
		f.validate = validatelocationreport;
		f.submitted = reportsubmitted;
		f.selectall('units');
//		f.set('skipblank', true);
		f.set('timezone', Timezone.calculate());
		if($('selecttype'))
			Event.observe($('selecttype'), 'change',  window, onSelectType);
	}
	if(pagename == 'scheduledreport.html'){
		function getOrdinal(n){
			var s =['ᵗʰ', 'ˢᵗ', 'ⁿᵈ', 'ʳᵈ'];
			v=n%100;
			return (s[(v-20)%10]||s[v]||s[0]);
		}
		function updateReportParameters() {
			var placeReports = ['timebyplacereport', 'mileagebyplacereport'];
			var placeReportParms = ['timeonsitereport', 'deliveryreport'];
			var distAndTime = ['mileagereport', 'activityreport', 'afterhoursreport'];
			var afterhours = ['afterhoursreport'];
			var noparms = ['locationreport', 'messagingreport', 'dailysummaryreport', 'export', 'schedulingreport', 'incidentreport']
			var reporttype = $('reporttype').value;
			DOM.show($('tr-csv'));
			DOM.show($('tr-skipblank'));
			if(afterhours.indexOf(reporttype) != -1) {
				DOM.show($('tr-workhours'));
				DOM.hide($('tr-placetype'));
				DOM.show($('tr-mintime'));
				DOM.show($('tr-mindist'));
				DOM.hide($('tr-io'));
			}
			else if(placeReports.indexOf(reporttype) != -1){
				DOM.hide($('tr-workhours'));
				DOM.show($('tr-placetype'));
				DOM.hide($('tr-mintime'));
				DOM.hide($('tr-mindist'));
				DOM.hide($('tr-io'));
			}
			else if(placeReportParms.indexOf(reporttype) != -1){
				DOM.hide($('tr-workhours'));
				DOM.show($('tr-placetype'));
				DOM.show($('tr-mintime'));
				DOM.show($('tr-mindist'));
				DOM.hide($('tr-io'));
			}
			else if(reporttype == 'stopsreport'){
				DOM.hide($('tr-workhours'));
				DOM.hide($('tr-placetype'));
				DOM.show($('tr-mintime'));
				DOM.show($('tr-mindist'));
				DOM.show($('tr-io'));
				DOM.setTxt($('td-io'), 'Calculate Idle Time on');
			}
			else if(reporttype == 'auxreport'){
				DOM.hide($('tr-workhours'));
				DOM.hide($('tr-placetype'));
				DOM.show($('tr-mintime'));
				DOM.hide($('tr-mindist'));
				DOM.show($('tr-io'));
				DOM.setTxt($('td-io'), 'IO');
			}
			else if(distAndTime.indexOf(reporttype) != -1){
				DOM.hide($('tr-workhours'));
				DOM.hide($('tr-placetype'));
				DOM.show($('tr-mintime'));
				DOM.show($('tr-mindist'));
				DOM.hide($('tr-io'));
				DOM.setTxt($('td-io'), 'IO');
			}
			else if(noparms.indexOf(reporttype) != -1){
				DOM.hide($('tr-workhours'));
				DOM.hide($('tr-placetype'));
				DOM.hide($('tr-mintime'));
				DOM.hide($('tr-mindist'));
				DOM.hide($('tr-io'));
				if(reporttype == 'export'){
					DOM.hide($('tr-csv'));
					DOM.hide($('tr-skipblank'));
				}
			}
		}
		var d = new Date();
		var monthday = $("monthday");
		for(var i = 1; i < 32; i++){
			var opt = DOM.OPTION({'value': i}, '')
			opt.innerHTML = i.toString() + " " +  getOrdinal(i).sup();
			DOM.addNodes(monthday, opt);
		}
		schedulef = new Form("schedule", 'schedulereport');
		if(gReportId == 'new'){
			schedulef.set('mintime', 120);
			schedulef.element('monthday').selectedIndex = d.getDate() - 1;
			schedulef.element('weekday').selectedIndex = d.getDay();
			schedulef.selectall('units');
		}
		else{
			var reportJob = unrepr(gReportInfo[1]);
			schedulef.set('frequency', gReportInfo[0]);
			schedulef.set('reporttype', reportJob['type']);
			updateReportParameters();
			if(gReportInfo[0] == 'day' || gReportInfo[0] == 'week')
				schedulef.set('skipweekends', gReportInfo[2])
			else
				schedulef.set('monthday', gReportInfo[2]);

			schedulef.set('mindist', reportJob['minStopDistance']);
			schedulef.set('timezone', reportJob['timezone']);
			schedulef.set('mintime', reportJob['minStopTime']);
			schedulef.set('skipblank', reportJob['skipblank']);
			schedulef.set('csv', reportJob['format'] == 'csv');
			schedulef.selectMultiple('units', Object.keys(reportJob['units']));
			onScheduleFrequency();
		}
		schedulef.validate = validatereportschedule;
		schedulef.submitted = reportschedulesubmitted;
		if(gReportId == 'new')
			schedulef.set('timezone', Timezone.calculate());
		schedulef.set('skipblank', true);
		Event.observe($('scheduleselecttype'), 'change',  window, onScheduleSelectType);
		Event.observe($('frequency'), 'change', window, onScheduleFrequency);
		Event.observe($('delete'), 'click', window, function(){
			if(gReportId == 'new')
				window.location = 'scheduledreports.html';
			else{
				Ajax.get('deleteschedule?id=' + gReportId, function(data, status) {
					if(status == 200) {
						data = eval(data);
						if(data === null)
							window.location = '/myaccount/';
						else if(data == true)
							window.location = 'scheduledreports.html';
						else
							DOM.setTxt($("submitstatus"), 'Error deleting report.');
					}
					else
						DOM.setTxt($("submitstatus"), 'Error deleting report.');
				})
			}
		});
		Event.observe($("reporttype"), "change", window, updateReportParameters);
	}
	else if(pagename == 'scheduledreports.html'){
		gReports = gReports;
		function getOrdinal(n){
			var s =['th', 'st', 'nd', 'rd'];
			v=n%100;
			return (s[(v-20)%10]||s[v]||s[0]);
		}
		Event.observe($("new"), 'click', window, function(evt) {
			window.location = "scheduledreport.html?id=new";
		});
		if (gReports.length > 0){
			tbl = new Table("reports", '/static/sort_asc.gif', '/static/sort_desc.gif', 1);
			gTable = tbl;
			for(var i = 0; i < gReports.length; i++){
				var report = unrepr(gReports[i][1]);
				var frequency = gReports[i][8];
				if(frequency == 'day'){
					frequency = 'Daily ';
					frequency += gReports[i][10].length.toString() + ' days a Week';
				}
				else if(frequency == 'month'){
					var ord = getOrdinal(gReports[i][2]);
					frequency = DOM.SPAN(null, 'Monthly on the ' + gReports[i][2], DOM.SUP(null, ord));
				}
				else{
					var weekday = gReports[i][10][0];
					frequency = 'Weekly on ' + weekday[0].toUpperCase() + weekday.slice(1)
				}
				var nextrun = ''
				if(gReports[i][7] != null){
					nextrunTs = CDateTime.fromTimestamp(gReports[i][7]);
					nextrun = nextrunTs.date.strftime('%d/%m/%Y');
				}
				if(gReports[i][2] != null)
					frequency == 'Monthly';
				var unitslen = Object.keys(report['units']).length.toString();
				if('fmt' in report)
					format = report['fmt'];
				else if('format' in report)
					format = report['format'];
				var formatImg = imgpng.load('doctypes/' + format + '.png', 28, 28);

				var tr = DOM.TR({'id': 'tr-' + gReports[i][0], 'className':'editLine'}, DOM.TD(null, DOM.style({'marginRight': '5px', 'verticalAlign': 'middle'}, imgpng.load('title/' + report['type'] + '.png', 24, 24)), ' ' + reportTypes[report['type']]), DOM.TD({'id': 'nextrun-' + gReports[i][0], 'align': 'center'}, nextrun), DOM.TD({'align': 'center'}, frequency), DOM.TD({'align': 'center'}, unitslen), DOM.TD({'align': 'center'}, formatImg), DOM.TD({'align': 'center'}, gReports[i][6]), DOM.TD({'id': 'status-' + gReports[i][0], 'align': 'center'}, DOM.A({'href':'scheduledreport.html?id=' + gReports[i][0]}, 'Edit / Delete')));
				row = [report['type'], gReports[i][7], frequency, unitslen, format, gReports[i][6], ''];
				tbl.addRow(row, tr);
			}
			tbl.populate();
			tbl.linkHeaders();
			tbl.sort();
			window.setInterval(updateSchedulePoll, 5000);
		}
	}

	else if (pagename == 'editdetails.html') {
		detailf = new Form("details", "details", null, $('submit'));
		detailf.validate = validatedetails;
		detailf.submitted = detailssubmitted;
		metrics = new FontMetrics({'font': '12px sans-serif'});

		iconPreview = new VIcon(false, 0, 0, vname, Vehicle.typeImage.call({'vehicletype': vehicletype}, true), 'arrows/stopped.png', 'Map Icon Preview', colour, false, ptocolour, function () {
		}, function () {
		}, metrics);
		iconPreview.__element__.style.zIndex = 10;
		DOM.addNodes($('preview'), iconPreview);

		iconPreview.measure();
		iconPreview.layout();
		pos = DOM.getPos($("preview"));

		dt = new Date().getFullYear();

		detailf.element('colour').value = colour;
		if(detailf.element('ptocolour'))
			detailf.element('ptocolour').value = ptocolour;
		detailf.element('vehicletype').value = vehicletype;

		DOM.show(iconPreview.__element__, true);
		iconPreview.__element__.style.position = 'relative';
		$('preview').style.minWidth = iconPreview._w + 'px';
		updateIcon = function(evt) {
			iconPreview.updateState('arrows/stopped.png', 'Map Icon Preview', detailf.val('colour'));
			png.setsource(iconPreview.vehicle, Vehicle.typeImage.call({'vehicletype': detailf.val('vehicletype')}, true));
			DOM.setTxt(iconPreview.label, detailf.val('name'));
			iconPreview.measure();
			iconPreview.layout();
		};

		Event.observe(detailf.element('colour'), 'change', window, updateIcon);
		Event.observe(detailf.element('vehicletype'), 'change', window, updateIcon);
		Event.observe(detailf.element('name'), 'change', window, updateIcon);
		Event.observe(detailf.element('name'), 'keyup', window, updateIcon);
	}
	else if (pagename == 'editalert.html') {
		require('./js/editalert.js')();
	}
	else if (pagename == 'details.html') {
		if (gv2) {
			detailf = new Form("details", "basics", null, $('detailsubmit'), $('detailsform'));
			detailf.preSubmitTxt =  'UPDATE DETAILS';
			infoform = new Form("vehicleinfo", "vehicleinfo", null, $('infosubmit'), $('vehicleinfoform'));
			infoform.preSubmitTxt = 'UPDATE INFO';
			if ($('configurationform')) {
				if (internal) {
					configform = new Form("configuration", "configure", null, $('configsubmit'), $('configurationform'));
					configform.validate = validateconfiguration;
					if (configdata['temperature'] == 'on') {
						DOM.show($('templimit'));
						DOM.show($('tempoptions'));
					}
					else {
						configdata['lowertemp2'] = '0';
						configdata['uppertemp2'] = '100';
					}
				}
				else if (boardtype == 'iphone' || (boardtype == 'pivotel' && unitmodel == 'SPT2')) {
					configform = new Form("configuration", "configiphone", null, $('configsubmit'), $('configurationform'));
					configform.validate = validateexternalconfig;
				}
				else if (boardtype == 'systech') {
					configform = new Form("configuration", "configsystech", null, $('configsubmit'), $('configurationform'));
					configform.validate = validatesystechconfig;

					Event.observe($('probetype'), 'change', window, setprobetype);

					if (configdata['probetype'] != '0') {
						configform.set('probetype', configdata['probetype'], true);
						DOM.show($('temprange'));
						setprobetype();
					}
				}
				else if (boardtype == 'atrack') {
					configform = new Form("configuration", "configexternal", null, $('configsubmit'), $('configurationform'));
					configform.validate = validateAtrackConfig;
					if (configdata['temperature'] == 'on') {
						DOM.show($('templimit'));
						DOM.show($('tempoptions'));
					}
					else {
						configdata['lowertemp2'] = '0';
						configdata['uppertemp2'] = '100';
					}
				}
				else if (boardtype == 'pivotel' && (unitmodel != 'None' && unitmodel != 'SPT2')) {
					configform = new Form("configuration", "configpivotel", null, $('configsubmit'), $('configurationform'));
					configform.validate = validatepivotelconfig;
					Event.observe($('DI2'), 'change', window, setDI2);
					configform.set('DI2', configdata['DI2'], true);
					setDI2();
				}
				else {
					configform = new Form("configuration", "configexternal", null, $('configsubmit'), $('configurationform'));
					configform.validate = validateexternalconfig;
				}
				configform.submitted = configurationsubmitted;
				configform.preSubmitTxt = 'SEND CONFIGURATION';
				for (var key in configdata) {
					var val = configdata[key];
					if (key == 'primary' || key == 'secondary') {
						if (val == '0')
							val = '';
						else
							val = '0' + val;
					}
					configform.set(key, val, true);
				}
				if (!supportpanic && internal) {
					configform.element('Ppanic').disabled = true;
					configform.element('Spanic').disabled = true;
				}
			}

			detailf.set("vehicletype", vehicletype);
			detailf.set("colour", colour);
			detailf.set("ptocolour", ptocolour);

			detailf.validate = validatedetails;
			detailf.submitted = configurationsubmitted;
			infoform.submitted = configurationsubmitted;
			infoform.validate = function (parms) {
				parms['id'] = vehicleid;
				return parms;
			};

			metrics = new FontMetrics({'font': '12px sans-serif'});

			iconPreview = new VIcon(false, 0, 0, vname, Vehicle.typeImage.call({'vehicletype': vehicletype}, true), 'arrows/stopped.png', 'Map Icon Preview', colour, false, ptocolour, function () {
			}, function () {
			}, metrics);
			iconPreview.__element__.style.zIndex = 10;
			DOM.addNodes($('preview'), iconPreview);

			iconPreview.measure();
			iconPreview.layout();
			$('preview').style.minWidth = iconPreview._w + 'px';
			pos = DOM.getPos($("preview"));

			dt = new Date().getFullYear();

			DOM.show(iconPreview.__element__, true);
			iconPreview.__element__.style.position = 'relative';
			updateIcon = function (evt) {
				iconPreview.updateState('arrows/stopped.png', 'Map Icon Preview', detailf.val('colour'));
				png.setsource(iconPreview.vehicle, Vehicle.typeImage.call({'vehicletype': detailf.val('vehicletype')}, true));
				DOM.setTxt(iconPreview.label, detailf.val('name'));
				iconPreview.measure();
				iconPreview.layout();
				$('preview').style.minWidth = iconPreview._w + 'px';
			};

			Event.observe(detailf.element('colour'), 'change', window, updateIcon);
			Event.observe(detailf.element('vehicletype'), 'change', window, updateIcon);
			Event.observe(detailf.element('name'), 'change', window, updateIcon);
			Event.observe(detailf.element('name'), 'keyup', window, updateIcon);

			if ($('tab-3')) {
				if ($('wof.year')) {
					var wofYearSel = $('wof.year');
					var regoYearSel = $('rego.year');
					var currentYear = new Date().getFullYear();
					for (var i = 0; i < 6; i++) {
						DOM.addNodes(wofYearSel, DOM.OPTION(null, String(currentYear + i)));
						DOM.addNodes(regoYearSel, DOM.OPTION(null, String(currentYear + i)));
					}
				}
				schedulef = new Form("schedule", "schedule", null, $('schedulesubmit'), $('scheduleform'));
				schedulef.preSubmitTxt = 'SAVE REMINDERS';
				schedulef.validate = validateschedule;
				schedulef.submitted = configurationsubmitted;
				for (var key in reminders) {
					var val = reminders[key];
					if (val == 'None')
						val = '';
					if (key == 'primary' || key == 'secondary') {
						if (val == '0')
							val = '';
						else
							val = '0' + val;
					}
					schedulef.set(key, val, true);
				}
				schedulef.set('wof.year', wofyear);
				schedulef.set('rego.year', regoyear);
			}

		}
		else {
			if($('ontime')) {
				ontimeform = new Form("ontime", "setontime", null, $('submitontime'));
				Event.observe($('updateontime'), 'click', window, function() {
					DOM.show($('dark-wrapper'));
					DOM.show($('ontimeupdater'));
				});
				if($('ontimebtn')) {
					Event.observe($('ontimebtn'), 'click', window, function() {
						DOM.show($('dark-wrapper'));
						DOM.show($('ontimeupdater'));
					});
				}
				Event.observe($('closeontime'), 'click', window, function() {
					DOM.hide($('dark-wrapper'));
					DOM.hide($('ontimeupdater'));
				});
				ontimeform.validate = function(parms) {
					this.clearErrors();
					parms['id'] = vehicleid;
					parms['ontime'] = parseDuration(parms['ontime']);
					if(parms['ontime'] === null) {
						this.setError('ontime');
						return null;
					}
					return parms;
				};
				ontimeform.submitted = function(data) {
					if(data == 'null') {
						window.location = '/myaccount/';
						return;
					}
					data = eval(data);
					if(data == true)
						window.location.reload();
				};
			}

			if ($('odometer')) {
				odoform = new Form("odometer", "setodo", null, $('submitodo'));

				function odoClose(evt){
					if(evt.type == 'keypress' || evt.type == 'keydown'){
						if(evt.keyCode != 27)
							return;
					}
					Event.stopObserving(window, 'keypress');
					$('odometer').reset();
					DOM.hide($('dark-wrapper'));
					DOM.hide($('odoupdater'));
				}
				function odoOpen(evt) {
					DOM.show($('dark-wrapper'));
					DOM.show($('odoupdater'));
					Event.observe(window, 'keypress', window, odoClose);
				}
				if($('odobtn')){
					Event.observe($('odobtn'), 'click', window, odoOpen);
				}
				if($('updateodo')){
					Event.observe($('updateodo'), 'click', window, odoOpen);
				}

				Event.observe($('closeodo'), 'click', window, odoClose);

				odoform.validate = function (parms) {
					this.clearErrors();
					parms['id'] = vehicleid;
					if (isNaN(parms['odo'])) {
						this.setError('odo');
						DOM.setTxt($("submitschedule"), 'Odometer override MUST be numerical');
						return null;
					}
					else if (parms['odo'] == '') {
						this.setError('odo');
						return null;
					}
					return parms;
				};

				odoform.submitted = function (data) {
					if (data == 'null') {
						window.location = '/myaccount/';
						return;
					}
					data = eval(data);
					DOM.removeChildren(this.elStatus);
					if(data == true)
						window.location.reload();
				};
			}

			if ($('hubometer')) {
				huboform = new Form("hubometer", "sethubo", null, $('submithubo'));

				function huboClose(evt){
					if(evt.type == 'keypress' || evt.type == 'keydown'){
						if(evt.keyCode != 27)
							return;
					}
					Event.stopObserving(window, 'keypress');
					$('hubometer').reset();
					DOM.hide($('dark-wrapper'));
					DOM.hide($('huboupdater'));
				}
				function huboOpen(evt) {
					DOM.show($('dark-wrapper'));
					DOM.show($('huboupdater'));
					Event.observe(window, 'keypress', window, huboClose);
				}
				if($('hubobtn')){
					Event.observe($('hubobtn'), 'click', window, huboOpen);
				}
				if($('updatehubo')){
					Event.observe($('updatehubo'), 'click', window, huboOpen);
				}

				Event.observe($('closehubo'), 'click', window, huboClose);

				huboform.validate = function (parms) {
					this.clearErrors();
					parms['id'] = vehicleid;
					if (isNaN(parms['hubo'])) {
						this.setError('hubo');
						DOM.setTxt($("submitschedule"), 'Hubometer override MUST be numerical');
						return null;
					}
					else if (parms['hubo'] == '') {
						this.setError('hubo');
						return null;
					}
					return parms;
				};

				huboform.submitted = function (data) {
					if (data == 'null') {
						window.location = '/myaccount/';
						return;
					}
					data = eval(data);
					DOM.removeChildren(this.elStatus);
					if(data == true)
						window.location.reload();
				};
			}

			function duration(startdate, enddate, recurs){
				var dtNow = new Date() / 1000;
				var time_formats = [
					[60, 'seconds', 1],
					[120, '1 minute', '1 minute from now'], // 60*2
					[3600, 'minutes', 60], // 60*60, 60
					[7200, '1 hour', '1 hour from now'], // 60*60*2
					[86400, 'hours', 3600], // 60*60*24, 60*60
					[172800, '1 day', 'tomorrow'], // 60*60*24*2
					[604800, 'days', 86400], // 60*60*24*7, 60*60*24
					[1209600, '1 week', 'next week'], // 60*60*24*7*4*2
					[2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
					[4838400, '1 month', 'next month'], // 60*60*24*7*4*2
					[29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
					[58060800, '1 year', 'next year'], // 60*60*24*7*4*12*2
					[2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
				];
				var seconds =  enddate - startdate;
				var list_choice = 1;
				var i = 0, format;
				while (format = time_formats[i++]){
					if (seconds < format[0]) {
						if (typeof format[2] == 'string' || seconds < 20){
							if(!recurs)
								return format[list_choice] + ', ' + duration(dtNow - (seconds % time_formats[i][2]), dtNow, true);
							else
								return format[list_choice];
						}
						else
							if(!recurs){
								return Math.floor(seconds / format[2]) + ' ' + format[1] + ', ' + duration(dtNow - (seconds % format[2]), dtNow, true) ;
							}
							else
								return Math.floor(seconds / format[2]) + ' ' + format[1];
					}
				}
				return '';
			}
			if($('editActionsList')){
				var headerEdit = new ActionsList($('editActionsList'), $('editActionsUL'));

			}
			if($('updateassignment')){
				tAssign = new Table("driverassigned", '/static/sort_asc.gif', '/static/sort_desc.gif', 1, false);
					if(typeof odoreading !== 'undefined')
						var odoCalc = new OdoOffset(odoreading);
				for(var i = 0; i < gDrivers.length; i++){
					var row = gDrivers[i];

//					assignmentid, userid, name, surname, starttime, endtime, notes
					var tstart = CDateTime.fromTimestamp(row[4]);
					var dtNow = new Date() / 1000;
					var dtEnd = dtNow;
					var tr = DOM.TR({'className': 'editLine'}, driverName = DOM.TD(null, DOM.A({'href': '/myaccount/userdetails.html?id=' + row[1]}, row[2] + ' ' + row[3])),
						rangeTD = DOM.TD(null, ''));
					if(typeof odoreading !== 'undefined'){
						var odoSpans = DOM.TD(null, DOM.I({'className': 'icon-signal'}, ''), DOM.SPAN({'className': 'darkerRow', 'id': 'start-' + row[0]}, ' '), odoEnd = DOM.SPAN({'className': 'darkerRow', 'id': 'end-' + row[0]}, ''));
						DOM.addNodes(tr, odoSpans);
					}
					DOM.addNodes(tr, DOM.TD(null, DOM.I({'className': 'icon-comments'}, ''),
										DOM.style({'marginLeft': '5px'}, DOM.SPAN(null, row[6].length.toString()))));
					tstarttxt = tstart.date.strftime('%d/%m/%Y') + ' ' + tstart.time.strftime('%h:%M');
					rangeSpan = DOM.SPAN({'className': 'darkerRow'}, '');
					currentSpan = DOM.SPAN({'className': 'greenAssigned'}, 'Current');
					if(row[5] == null){
						rangeSpan.innerHTML = tstarttxt + '&nbsp;&mdash;&nbsp;' + 'No End Date';
						if(typeof odoreading !== 'undefined'){
							odoEnd.innerHTML = '&nbsp;&mdash;&nbsp;' + 'No End Reading';
							odoEnd.className = '';
						}
						if(row[4] <  dtNow){
							var timeassigned =  DOM.style({'marginLeft': '5px'}, DOM.SPAN(null, '(' +  duration(row[4], dtNow) + ')'));
							DOM.addNodes(rangeTD, rangeSpan, timeassigned);
						}
						else
							DOM.addNodes(rangeTD, rangeSpan);
					}
					else{
						var dtEnd = row[5];
						var tend = CDateTime.fromTimestamp(row[5]);
						tend = tend.date.strftime('%d/%m/%Y') + ' ' + tend.time.strftime('%h:%M');
						rangeSpan.innerHTML = tstarttxt +  '&nbsp;&mdash;&nbsp;' + tend;
						if(row[4] < dtNow){
							var timeassigned = DOM.style({'marginLeft': '5px'}, DOM.SPAN(null, '(' + duration(row[4], row[5]) + ')'));
							DOM.addNodes(rangeTD, rangeSpan, timeassigned);
						}
					}

					if(row[4] < dtNow && (row[5] == null || row[5] > dtNow)){
						DOM.addNodes(driverName, currentSpan);
					}
					if(typeof odoreading !== 'undefined'){
						if(i == 0){
							if(!(row[5] == null || row[5] > dtNow)){
								odoCalc.append(dtEnd, dtNow, null, null);
	//							Ajax.get('/myaccount/getunitdistsummary?unitid=' + vehicleid + '&starttime=' + parseInt(dtEnd) + '&endtime=' + parseInt(dtNow), updateOdos);
							}
						}
						else{
							if(dtEnd != gDrivers[i - 1][4]){
								odoCalc.append(parseInt(dtEnd), parseInt(gDrivers[i-1][4]), null, null);
	//							Ajax.get('/myaccount/getunitdistsummary?unitid=' + vehicleid + '&starttime=' + parseInt(dtEnd) + '&endtime=' + parseInt(gDrivers[i-1][4]), updateOdos);
							}
						}
						odoCalc.append(row[4], dtEnd, 'start-' + row[0], 'end-' + row[0]);
	//					Ajax.get('/myaccount/getunitdistsummary?unitid=' + vehicleid + '&starttime=' + row[4] + '&endtime=' + parseInt(dtEnd), updateOdos);
					}
					var sortrow = [row[2], row[4]];
					tAssign.addRow(sortrow, tr);
				}

				tAssign.populate();
				tAssign.linkHeaders();

				if(typeof odoreading !== 'undefined')
					odoCalc.onReady();

				if(gDrivers.length > 0){
					DOM.hide($('nodrivers'));
					DOM.show($('assignments_div'));
				}

				driverform = new Form("assignment", "driverassign", null, $('submitassign'));
				Event.observe($('driverbtn'), 'click', window, function (evt) {
					DOM.show($('dark-wrapper'));
					DOM.show($('updateassignment'));
				});
				Event.observe($('closeassignment'), 'click', window, function (evt) {
					$('assignment').reset();
					DOM.hide($('dark-wrapper'));
					DOM.hide($('updateassignment'));
				});
				driverform.validate = function(parms){
					parms['unitid'] = vehicleid;
					return parms;
				}
				driverform.submitted = function(data) {
					if (data == 'null') {
						window.location = '/myaccount/';
						return;
					}
					data = eval(data);
					DOM.removeChildren(this.elStatus);
					if (data == true) {
						var fa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
						var spanTxt = DOM.SPAN(null, "Done");

						DOM.addNodes(this.elStatus, fa, spanTxt);
						setTimeout(function () {
							window.location.reload();
						}.bind(this), 2000);
					}
				};
			}
			if ($('vehiclealerts')) {
				tN = new Table("vehiclealerts", '/static/sort_asc.gif', '/static/sort_desc.gif', 0, null, [4]);
				for (var i = 0; i < alertdata.length; i++) {

					var row = alertdata[i];
					var subscribed = false;
					for(var idx = 0; idx < row['subscriptions'].length; idx++){
						if(row['subscriptions'][idx][0] == userid)
							subscribed = true;
					}
					var actionsButton = DOM.DIV({'className': 'btn'},
						DOM.I({'className': 'icon-cog'}, ''),
						DOM.style({'marginLeft': '5px'}, DOM.SMALL(null, DOM.I({'className': 'icon-caret-down'})))
					);
					var actionsList = new ActionsList(actionsButton);
					var editLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Edit Alert'));
					DOM.addNodes(actionsList.unorderedList,editLI);
					Event.observe(editLI, 'click', window, (function(value){
						return function(){
							window.location.href='editalert.html?alertid=' + value['id'];}
						}
					)(row));

					DOM.addNodes(actionsList.unorderedList, DOM.HR({'className': 'actions-hr'}));

					var subscribeTxt = subscribed ? 'Unsubscribe' : 'Subscribe';
					var iconClass = subscribed ? 'icon-minus-sign' : 'icon-plus-sign';
					var subscribeLI = DOM.LI(null, DOM.I({'className': iconClass}, ''), DOM.SPAN(null, subscribeTxt));
					DOM.addNodes(actionsList.unorderedList, subscribeLI);

					if (subscribed){
						Event.observe(subscribeLI, 'click', window, (function(value){
							return function(){
								Ajax.get('subscribe?alertid=' + value['id'] + '&subscribe', function(data, stat){window.location.reload()})
							}
						})(row));
					}
					else{
						Event.observe(subscribeLI, 'click', window, (function(value){
							return function(){
								Ajax.get('subscribe?alertid=' + value['id'] + '&subscribe=on', function(data, stat){window.location.reload()})
							};
						})(row));
					}

					var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, str(row['type'])), DOM.TD(null, row['description']), DOM.TD(null, row['subscriptions'].length.toString()));
					tr.appendChild(checkcell(subscribed));
					tr.appendChild(DOM.TD(null, actionsButton));
					var sortrow = [str(row['type']), row['description'], row['subscriptions'].length, subscribed];
					tN.addRow(sortrow, tr);
				}
				if (alertdata.length > 0) {
					DOM.hide($('noalerts'));
					DOM.show($('alertdiv'));
				}
				tN.populate();
				tN.linkHeaders();
			}
		}
	}
	else if(pagename == 'editvehicles.html') {
		t = new Table("config", '/static/sort_asc.gif', '/static/sort_desc.gif');
		for(unit in configdata) {
			var row = configdata[unit];
			var name = row['name'];
			var phone = row['phone'];
			var model = row['model'] || '';
			var make = row['make'] || '';
			var rego = row['rego'] || '';
			var odo = row['odo'];
			if(phone)
				phone = '0' + phone;
			else
				phone = '';

			var type = row['vehicletype'] || 'Car';

			if(rego != '')
				rego = DOM.TD({'className':'plate'}, rego);
			else
				rego = DOM.TD(null, '');

			var tr = DOM.TR({'id': 'tr-' + unit, 'className':'editLine'}, DOM.TD(null, DOM.A({'href':'details.html?id=' + unit}, name), DOM.BR(), make + ' ' + model), rego, DOM.TD({'align': 'center'}, png.load(typeImage(type), 24, 24)), DOM.TD(null, 'Ph: ' + phone, DOM.BR(), 'Serial: ' + unit),
					DOM.TD({'align': 'center'}, odo === null || odo == 0 ? '' :  (odo.toFixed(2) + ' km')), DOM.TD({'align': 'center'}, formatLastAccess(row['time'])), DOM.TD(null, viewlink = DOM.A({'className': 'btn', 'href': '/myaccount/map.html?unit=' + unit, 'style': 'display: block'}, DOM.I({'className': 'icon-map-marker'}, ''))));

//			DOM.addNodes(tr, DOM.TD(null, (row[3] && (isSnitchArmada(row[5]))) ? 'Sending...' : ''));

			row = [row['name'], rego, type, unit, odo, row['time']];
			t.addRow(row, tr);
		}

		t.populate();
		t.linkHeaders();

		Event.observe($('inputsearch'), 'keyup', window, function(){
			for(unitdata in configdata){
				var row = configdata[unitdata];
				var iptext = $('inputsearch').value;
				function matchText(x, y){
					if(y == '')
						return true;
					else if(x == '' || x == null)
						return false;
					return x.toUpperCase().indexOf(y.toUpperCase()) != -1;
				}
				if(matchText(row['name'], iptext) || matchText(row['rego'], iptext) || matchText(row['make'] + ' ' + row['model'], iptext))
					DOM.show($('tr-' + unitdata));
				else
					DOM.hide($('tr-' + unitdata));
			}
			return true;
		});

		for(unitdata in configdata) {
			var row = configdata[unitdata];
			Event.observe($('tr-' + unitdata), 'click', window, function(evt) {
				window.location = findFirstElem(evt.target).firstChild.firstChild.href;
			});
		}
	}
	else if(pagename == 'billing.html') {
		t = new Table('billing', '/static/sort_asc.gif', '/static/sort_desc.gif');
		for(var i = 0; i < billingdata.length; i++) {
			var row = billingdata[i];
			var url;
			if(row[4] == 'Invoice')
				url = 'viewinvoice.html?id=' + row[1];
			else if(row[4] == 'Payment')
				url = 'viewpayment.html?id=' + row[1];
			else if(row[4] == 'Credit')
				url = 'viewcredit.html?id=' + row[1];
			else if(row[4] == 'Refund')
				url = 'viewrefund.html?id=' + row[1];

			var tr = DOM.TR(null, DOM.TD(null, row[0]), DOM.TD({'align': 'center'}, DOM.A({'href': url}, row[4] + ' ' + row[1])), DOM.TD({'align': 'center'}, row[2]), DOM.TD({'align': 'center'}, row[3].toFixed(2)));
			t.addRow(row, tr);
		}
		t.populate();
		t.linkHeaders();
	}
	else if (pagename == 'editusers.html') {
		t = new Table("config", '/static/sort_asc.gif', '/static/sort_desc.gif', 0, null, [5]);
		userdata = userdata[1];
		for (var i = 0; i < userdata.length; i++) {
			var row = userdata[i];
			if (row[3] != 'Never logged on' && row[3] != 'No Recorded Activity')
				row[3] = formatLastAccess(row[3]);
			var userlevel = '';
			if (has(row[5], 'editusers'))
				userlevel = 'Account Administrator';
			else if (has(row[5], 'configure'))
				userlevel = 'Vehicle Administrator';
			else if (has(row[5], 'map'))
				userlevel = 'Standard User';
			else if (has(row[5], 'fmi'))
				userlevel = 'Messaging Dispatcher';

			var actionsButton = DOM.DIV({'className': 'btn'},
				DOM.I({'className': 'icon-cog'}, ''),
				DOM.style({'marginLeft': '5px'}, DOM.SMALL(null, DOM.I({'className': 'icon-caret-down'})))
			);
			var actionsList = new ActionsList(actionsButton);
			var editLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Edit User'));
			DOM.addNodes(actionsList.unorderedList,editLI);
			Event.observe(editLI, 'click', window, (function(value){
				return function(){
					window.location.href='userdetails.html?id=' + value[0];
				}
			})(row));

			// Add user subscriptions link
			var subsLI = DOM.LI(null, DOM.I({'className': 'icon-bell'}, ''), DOM.SPAN(null, 'Subscriptions'));
			DOM.addNodes(actionsList.unorderedList, subsLI);
			Event.observe(subsLI, 'click', window, (function(value){
				return function() {
					window.location = 'useralerts.html?user=' + value[0];
				}
			}(row)));

			if(row[0] != thisuser){
				var deleteLI = DOM.LI(null, DOM.I({'className': 'icon-trash'}, ''), DOM.SPAN(null, 'Delete User'));
				var clearLI = DOM.LI(null, DOM.I({'className': 'icon-remove-sign'}, ''), DOM.SPAN(null, 'Clear Password'));
				DOM.addNodes(actionsList.unorderedList, clearLI);
				DOM.addNodes(actionsList.unorderedList, DOM.HR({'className': 'actions-hr'}));
				DOM.addNodes(actionsList.unorderedList, deleteLI);

				Event.observe(deleteLI, 'click', window, (function(value){
					return function(){
						if(confirm("Are you sure you wish to delete this user?")) {
							Ajax.get('deleteuser?id=' + value[0], function (data, status) {
								var error_div = $('error_div');
								var errorFa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-exclamation-sign'}, ' '));
								var successFa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
								while (error_div.firstChild)
									error_div.removeChild(error_div.firstChild);
								if (status == 200) {
									data = eval(data);
									if (data === null)
										window.location = '/myaccount/';
									else if (data == true){
										error_div.className = 'success_margined';
										DOM.show($('error_div'));
										DOM.addNodes(error_div, successFa);
										DOM.addNodes($('error_div'), DOM.SPAN(null, 'User successfully deleted'));
										window.setTimeout(function () {
											window.location.reload();
										}, 2500);
									}
									else{
										error_div.className = 'error_div';
										DOM.show($('error_div'));
										DOM.addNodes(error_div, errorFa);
										DOM.addNodes($('error_div'), DOM.SPAN(null, 'Error deleting user.'));
									}
								}
								else{
									error_div.className = 'error_div';
									DOM.show($('error_div'));
									DOM.addNodes(error_div, errorFa);
									DOM.addNodes($('error_div'), DOM.SPAN(null, 'Error deleting user.'));
								}
							});
						}
					}
				})(row));
				Event.observe(clearLI, 'click', window, (function(value){
					return function(){
						if(confirm("Are you sure you wish to clear this user\'s password?")) {
							Ajax.get('resetpass?id=' + value[0], function (data, status) {
								var error_div = $('error_div');
								var errorFa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-exclamation-sign'}, ''));
								var successFa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
								while (error_div.firstChild)
									error_div.removeChild(error_div.firstChild);
								if (status == 200) {

									data = eval(data);
									if (data === null)
										window.location = '/myaccount/';
									else if (data == true){
										error_div.className = 'success_div';
										DOM.show($('error_div'));
										DOM.addNodes(error_div, successFa);
										DOM.addNodes($('error_div'), DOM.SPAN(null, 'User\'s password successfully cleared'));
										window.setTimeout(function () {
											DOM.hide($('error_div'));
										}, 2500);
									}
									else{
										error_div.className = 'error_div';
										DOM.show($('error_div'));
										DOM.addNodes(error_div, errorFa);
										DOM.addNodes($('error_div'), DOM.SPAN(null, 'Error clearing password.'));
									}
								}
								else{
									error_div.className = 'error_div';
									DOM.show($('error_div'));
									DOM.addNodes(error_div, errorFa);

									DOM.addNodes($('error_div'), DOM.SPAN(null, 'Error clearing password.'));
								}

							});
						}
					}
				})(row));
			}
			var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, DOM.A({'href': 'userdetails.html?id=' + row[0]}, str(row[1]))), DOM.TD(null, str(row[2])), DOM.TD(null, str(row[3])), DOM.TD(null, str(row[4])), DOM.TD(null, userlevel), DOM.TD(null, ''), DOM.TD(null, actionsButton));
			var jsrow = [row[1], row[2], row[3], row[4], '', userlevel];
			t.addRow(jsrow, tr);
		}
		t.populate();
		t.linkHeaders();
		Event.observe($("new"), 'click', window, function(evt) {
			window.location = "userdetails.html?id=new";
		});
	}

	else if(pagename == 'editdrivers.html') {
			assignform = new Form("assign", "assigndriver", null, $('submitassign'));

		function assignClose(evt){
			if(evt.type == 'keypress' || evt.type == 'keydown'){
				if(evt.keyCode != 27)
					return;
			}
			Event.stopObserving(window, 'keypress');
			$('assign').reset();
//				DOM.hide($('dark-wrapper'));
			DOM.hide($('assignunit'));
		}
		function assignOpen(vid) {
			gDriverId = vid;
//				DOM.show($('dark-wrapper'));
			DOM.show($('assignunit'));
			Event.observe(window, 'keypress', window, assignClose);
		}
/*			if($('odobtn')){
			Event.observe($('odobtn'), 'click', window, assignOpen);
		}
		if($('updateodo')){
			Event.observe($('updateodo'), 'click', window, assignOpen);
		}*/

		Event.observe($('close'), 'click', window, assignClose);

		assignform.validate = function (parms) {
			this.clearErrors();
			parms['driver'] = gDriverId;
			return parms;
		};

		assignform.submitted = function (data) {
			if (data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			data = eval(data);
			DOM.removeChildren(this.elStatus);
			if (data == true) {
				var fa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
				var spanTxt = DOM.SPAN(null, "Done");

				DOM.addNodes(this.elStatus, fa, spanTxt);
				setTimeout(function () {
					window.location.reload();
				}, 1000);
			}
		};

		t = new Table("config", '/static/sort_asc.gif', '/static/sort_desc.gif', 0, null, [5]);
		driverdata = driverdata[1];
		for (var i = 0; i < driverdata.length; i++) {
			var row = driverdata[i];

			var actionsButton = DOM.DIV({'className': 'btn'},
				DOM.I({'className': 'icon-cog'}, ''),
				DOM.style({'marginLeft': '5px'}, DOM.SMALL(null, DOM.I({'className': 'icon-caret-down'})))
			);
			var actionsList = new ActionsList(actionsButton);
			var editLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Edit Driver'));
			DOM.addNodes(actionsList.unorderedList,editLI);
			Event.observe(editLI, 'click', window, (function(value){
				return function(){
					window.location.href='driverdetails.html?id=' + value[0];
				}
			})(row));

			var assignLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Assign To Vehicle'));
			DOM.addNodes(actionsList.unorderedList, assignLI);
			Event.observe(assignLI, 'click', window, (function(value){
				return function() {
					assignOpen(value[0]);
				}
			})(row));

			var vehicle = d2v[row[0]];
			var _vname = '';
			if(vehicle)
				_vname = vehicle.name;

			if(vehicle) {
				var unassignLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Unassign From Vehicle'));
				DOM.addNodes(actionsList.unorderedList, unassignLI);
				Event.observe(unassignLI, 'click', window, (function(value){
					return function() {
						if(confirm("Unassign driver from vehicle?")) {
							Ajax.get('unassigndriver?driver=' + value[0], function(data, status) {
								if(status == 200) {
									window.location.reload();
								}
								else {
									alert("Failed to unassign driver");
								}
							});
						}
					}
				})(row));
			}

			var deleteLI = DOM.LI(null, DOM.I({'className': 'icon-trash'}, ''), DOM.SPAN(null, 'Delete Driver'));
			DOM.addNodes(actionsList.unorderedList, DOM.HR({'className': 'actions-hr'}));
			DOM.addNodes(actionsList.unorderedList, deleteLI);

			Event.observe(deleteLI, 'click', window, (function(value){
				return function(){
					if(confirm("Are you sure you wish to delete this driver?")) {
						Ajax.get('deletedriver?id=' + value[0], function (data, status) {
							var error_div = $('error_div');
							var errorFa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-exclamation-sign'}, ' '));
							var successFa = DOM.style({'marginRight': '5px'}, DOM.I({'className': 'icon-thumbs-up'}, ''));
							while (error_div.firstChild)
								error_div.removeChild(error_div.firstChild);
							if (status == 200) {
								data = eval(data);
								if (data === null)
									window.location = '/myaccount/';
								else if (data == true){
									error_div.className = 'success_margined';
									DOM.show($('error_div'));
									DOM.addNodes(error_div, successFa);
									DOM.addNodes($('error_div'), DOM.SPAN(null, 'Driver successfully deleted'));
									window.setTimeout(function () {
										window.location.reload();
									}, 2500);
								}
								else{
									error_div.className = 'error_div';
									DOM.show($('error_div'));
									DOM.addNodes(error_div, errorFa);
									DOM.addNodes($('error_div'), DOM.SPAN(null, 'Error deleting driver.'));
								}
							}
							else{
								error_div.className = 'error_div';
								DOM.show($('error_div'));
								DOM.addNodes(error_div, errorFa);
								DOM.addNodes($('error_div'), DOM.SPAN(null, 'Error deleting driver.'));
							}
						});
					}
				}
			})(row));


			var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, DOM.A({'href': 'driverdetails.html?id=' + row[0]}, str(row[1]))), DOM.TD(null, str(row[2])), DOM.TD(null, _vname), DOM.TD(null, actionsButton));
			var jsrow = [row[1], row[2], _vname];
			t.addRow(jsrow, tr);
		}
		t.populate();
		t.linkHeaders();
		Event.observe($("new"), 'click', window, function(evt) {
			window.location = "driverdetails.html?id=new";
		});

	}

	else if (pagename == 'vehiclegroups.html') {
		var checks = {};
		Event.observe($("new"), 'click', window, function (evt) {
			window.location = "groupdetails.html?id=new";
		});
		if(groupdata.length > 0) {
			if ($('selall'))
				t = new Table('config', '/static/sort_asc.gif', '/static/sort_desc.gif', 1, null, [0]);
			else
				t = new Table('config', '/static/sort_asc.gif', '/static/sort_desc.gif');
			for (var i = 0; i < groupdata.length; i++) {
				var row = groupdata[i];
				// 			DOM.style({'position': 'relative', 'textAlign': 'center', 'padding': '15px 0'}
				var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, str(row[1])), DOM.TD({'align': 'center'}, str(row[2])), DOM.TD({'align': 'center'}, str(row[3])), DOM.TD({'align': 'center'}, DOM.A({'href': 'groupdetails.html?id=' + row[0]}, 'Edit')));
				if ($('selall')) {
					var tdDelCheck = DOM.style({'position': 'relative', 'textAlign': 'center', 'padding': '15px 0'}, DOM.TD(null, chkbox = DOM.INPUT({'id': 'chk-' + row[0], 'type': 'checkbox', 'className': 'standardCheck'}), DOM.LABEL({'htmlFor': 'chk-' + row[0]})));
					tr.insertBefore(tdDelCheck, tr.firstChild);
					checks[row[0]] = chkbox;
				}

				//			var tr = DOM.TR(null, DOM.TD(null, str(row[1])), DOM.TD(null, str(row[3])), DOM.TD(null, DOM.A({'href': 'placealert.html?id=' + row[0]}, 'Edit')), DOM.TD(null, row[7]>0?str(row[7]):''), DOM.TD(null, row[6]==true?str(row[6]):''));
				var jsrow = [str(row[1]), str(row[3]), 0, str(row[7]), row[6]];
				t.addRow(jsrow, tr);
			}
			t.populate();
			t.sort();
			t.linkHeaders();

			if ($('selall')) {
				for (var i = 0; i < groupdata.length; i++) {
					var row = groupdata[i];
					Event.observe($('chk-' + row[0]), 'click', window, function () {
						var allTrue = true;
						var allFalse = true;
						for (var check in checks) {
							allTrue = checks[check].checked == true && allTrue;
							allFalse = checks[check].checked == false && allFalse;
						}
						if (allTrue || allFalse) {
							$("selall").checked = allTrue;
							$("selallfor").className = '';
							$("delreport").disabled = allFalse;
						}
						else {
							$("delreport").disabled = false;
							$("selallfor").className = 'tristate';
						}
					});
				}

				Event.observe($('selall'), 'click', window, function () {
					if (groupdata.length == 0)
						$("selallfor").className = 'disabled';
					else {
						$("selallfor").className = '';
						$('delreport').disabled = ! $('selall').checked;
						for (var i = 0; i < groupdata.length; i++) {
							$('chk-' + groupdata[i][0]).checked = $('selall').checked;
						}
					}
				});

				Event.observe($('delreport'), 'click', window, function () {
					if(! $('delreport').disabled) {
						var toDelete = '';
						for (chk in checks) {
							if (checks[chk].checked) {
								if (toDelete != '')
									toDelete += '&'
								toDelete += 'gid=' + chk;
							}
						}
						DOM.setTxt($("delreport"), "Deleting...");
						Ajax.get('deletegroup?' + toDelete, function (data, status) {
							if(status == 200) {
								data = eval(data);
								if (data === null)
									window.location = '/myaccount/';
								else if (data == true)
									window.location = 'vehiclegroups.html';
								else
									setError($('delreport'), 'Error deleting group.');
							}
							else
								setError($('delreport'), 'Error deleting group.');
						});
					}
				});
			}
		}
	}
	else if(pagename == 'useralerts.html') {
		ta = new Table('emailalerts', '/static/sort_asc.gif', '/static/sort_desc.gif', 0, null, [4]);
		for (var i = 0; i < alertdata.length; i++) {
			var row = alertdata[i];

			var actionsButton = DOM.DIV({'className': 'btn'},
				DOM.I({'className': 'icon-cog'}, ''),
				DOM.style({'marginLeft': '5px'}, DOM.SMALL(null, DOM.I({'className': 'icon-caret-down'})))
			);

			var actionsList = new ActionsList(actionsButton);
			var editLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Edit Alert'));

			DOM.addNodes(actionsList.unorderedList, editLI);

			Event.observe(editLI, 'click', window, (function(value){
				return function(){
					window.location.href = 'editalert.html?alertid=' + value['id'];
				}
			})(row));

			var unsubLI = DOM.LI(null, DOM.I({'className': 'icon-minus-sign'}, DOM.SPAN(null, 'Unsubscribe')));
			DOM.addNodes(actionsList.unorderedList, unsubLI);

			Event.observe(unsubLI, 'click', window, (function(value){
				return function(){
					Ajax.get('unsubscribe?alertid=' + value['id'] + '&user=' + userid, function(data, stat){window.location.reload()})
				};
			})(row));

			function formatUnits(units, listmax) {
				if(units.length == 0)
					return {title: null, txt: "None"};
				var display = [];
				for(var i = 0; i < units.length; i++) {
					if(display.length < listmax)
						display.push(units[i][1]);
					else {
						display.push("and " + str(units.length - display.length) + " others");
						break;
					}
				}
				var txt = display.join(', ');
				return {title: null, txt: txt};
			}

			var editurl = 'editalert.html?alertid=' + row['id'];
			var units = formatUnits(row['units'], 2);
			var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, DOM.A({href: editurl}, str(row['type']))),
				DOM.TD(null, DOM.A({href: editurl}, row['description'])),
				DOM.TD(null, units.txt),
				DOM.TD(null, actionsButton));
			var sortrow = [str(row['type']), row['description'], row['units'].length];
			ta.addRow(sortrow, tr);
		}

		ta.populate();
		ta.sort();
		ta.linkHeaders();
	}
	else if(pagename == 'emailalerts.html') {
		if ($('placealerts')) {
			t = new Table('placealerts', '/static/sort_asc.gif', '/static/sort_desc.gif');
			for (var i = 0; i < placedata.length; i++) {
				var row = placedata[i];
				var tr = DOM.TR({'id': 'tr-' + row[0], 'className': 'editLine'}, DOM.TD(null, str(row[1])), DOM.TD(null, str(row[3])), DOM.TD(null, DOM.A({'href': 'placealert.html?id=' + row[0]}, 'Edit')), DOM.TD(null, str(row[8])), DOM.TD(null, str(row[7])));
				tr.appendChild(checkcell(row[6]));
				var jsrow = [str(row[1]), str(row[3]), 0, str(row[8]), row[7], row[6]];
				t.addRow(jsrow, tr);
			}
			Event.observe($('inputsearch'), 'keyup', window, function () {
				for (var i = 0; i < placedata.length; i++) {
					var row = placedata[i];
					var iptext = $('inputsearch').value;

					function matchText(x, y) {
						if (y == '')
							return true;
						else if (x == '' || x == null)
							return false;
						return x.toUpperCase().indexOf(y.toUpperCase()) != -1;
					}

					if (matchText(row[1], iptext) || matchText(row[3], iptext))
						DOM.show($('tr-' + row[0]));
					else
						DOM.hide($('tr-' + row[0]));
				}
				return true;
			});
			t.populate();
			t.sort();
			t.linkHeaders();
		}
		if($("new")){
			Event.observe($("new"), 'click', window, function (evt) {
				window.location = "editalert.html?alertid=new";
			});
		}
		if ($('emailalerts')) {
			ta = new Table('emailalerts', '/static/sort_asc.gif', '/static/sort_desc.gif', 0, null, [4]);
			for (var i = 0; i < alertdata.length; i++) {
				var row = alertdata[i];

				var subscribed = false;
					for(var idx = 0; idx < row['subscriptions'].length; idx++){
						if(row['subscriptions'][idx][0] == userid)
							subscribed = true;
					}
				var actionsButton = DOM.DIV({'className': 'btn'},
					DOM.I({'className': 'icon-cog'}, ''),
					DOM.style({'marginLeft': '5px'}, DOM.SMALL(null, DOM.I({'className': 'icon-caret-down'})))
				);

				var actionsList = new ActionsList(actionsButton);
				var editLI = DOM.LI(null, DOM.I({'className': 'icon-pencil'}, ''), DOM.SPAN(null, 'Edit Alert'));

				DOM.addNodes(actionsList.unorderedList, editLI);

				Event.observe(editLI, 'click', window, (function(value){
					return function(){
						window.location.href = 'editalert.html?alertid=' + value['id'];
					}
				})(row));

				var subscribeTxt = subscribed ? 'Unsubscribe' : 'Subscribe';
				var iconClass = subscribed ? 'icon-minus-sign' : 'icon-plus-sign';
				var subscribeLI = DOM.LI(null, DOM.I({'className': iconClass}, ''), DOM.SPAN(null, subscribeTxt));
				DOM.addNodes(actionsList.unorderedList, subscribeLI);

				if (subscribed){
					Event.observe(subscribeLI, 'click', window, (function(value){
						return function(){
							Ajax.get('subscribe?alertid=' + value['id'] + '&subscribe', function(data, stat){window.location.reload()})
						}
					})(row));
				}
				else{
					Event.observe(subscribeLI, 'click', window, (function(value){
						return function(){
							Ajax.get('subscribe?alertid=' + value['id'] + '&subscribe=on', function(data, stat){window.location.reload()})
						};
					})(row));
				}


				if(userfeatures.indexOf('editusers') != -1 || row['subscriptions'].length == 0 || (row['subscriptions'].length == 1 && row['subscriptions'][0][0] == userid)){
					DOM.addNodes(actionsList.unorderedList, DOM.HR({'className': 'actions-hr'}));
					var deleteLI = DOM.LI(null, DOM.I({'className': 'icon-remove-sign'}, ''), DOM.SPAN(null, 'Delete Alert'));
					DOM.addNodes(actionsList.unorderedList, deleteLI);
					Event.observe(deleteLI, 'click', window, (function(value){
						return function(){
							if(confirm("Are you sure you wish to delete this alert? This will affect all units assigned to the alert...")){
								Ajax.get("deletealert?alertid=" + value['id'], function(){
									window.location.reload();
								})
							}
						}
					})(row));
				}

				function formatUnits(units, listmax) {
					if(units.length == 0)
						return {title: null, txt: "None"};
					var display = [];
					for(var i = 0; i < units.length; i++) {
						if(display.length < listmax)
							display.push(units[i][1]);
						else {
							display.push("and " + str(units.length - display.length) + " others");
							break;
						}
					}
					var txt = display.join(', ');
					return {title: null, txt: txt};
				}
				function formatSubscriptions(subs) {
					if(subs.length == 0)
						return {title: "None", txt: "None"};
					var you = false;
					for(var i = 0; i < subs.length; i++)
						if(subs[i][0] == userid)
							you = true;
					var txt;
					if(you) {
						if(subs.length == 1)
							txt = "Just You";
						else
							txt = "You and " + str(subs.length - 1) + " others";
					}
					else
						txt = str(subs.length) + " others";
					return {title: null, txt: txt};
				}

				var editurl = 'editalert.html?alertid=' + row['id'];
				var units = formatUnits(row['units'], 2);
				var subs = formatSubscriptions(row['subscriptions']);
				var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, DOM.A({href: editurl}, str(row['type']))),
					DOM.TD(null, DOM.A({href: editurl}, row['description'])),
					DOM.TD(null, units.txt),
					DOM.TD(null, subs.txt),
					DOM.TD(null, actionsButton));
				var sortrow = [str(row['type']), row['description'], row['units'].length, row['subscriptions'].length];
				ta.addRow(sortrow, tr);
			}


			ta.populate();
			ta.sort();
			ta.linkHeaders();
		}


// 		f.submitted = function(data) {
// 			if(data == 'null') {
// 				window.location = '/myaccount/';
// 				return;
// 			}
// 			window.location.reload();
// 		};
// 		f.validate = function(parms){
// 			if(!parms['alertuser'])
// 				parms['alertuser'] = '';
// 			if(parms['primary'] && !validemail(parms['primary'])){
// 				this.setError('primary');
// 				return null;
// 			}
// 			if(parms['secondary'] && !validemail(parms['secondary'])){
// 				this.setError('secondary');
// 				return null
// 			};
// 			return parms;
// 		}
	}
	else if(pagename == 'placealert.html') {
		f = new Form("assign", "placeassign", null, $('submitstatus'));
		t = new Table("config", '/static/sort_asc.gif', '/static/sort_desc.gif');
		var vehicledata = assigndata[0];
		var assigned = assigndata[1];
		var checks = {};		/* thanks again IE */
		for(var i = 0; i < vehicledata.length; i++) {
			var row = vehicledata[i];
			var phone = str(row[2]);
			if(phone)
				phone = '0' + phone;
			var type = row[4] || 'Car';
			var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, str(row[1])), DOM.TD(null, type), DOM.TD(null, phone),
				DOM.style({'textAlign': 'center'}, DOM.TD(null, checks['v' + str(row[0])] = DOM.INPUT({'type': 'checkbox', 'className': 'standardCheck', 'id': 'v' + str(row[0]), 'name': 'v' + str(row[0])}), DOM.LABEL({'htmlFor': 'v' + str(row[0])}))));
			row = [row[1], type, phone, row[0]];
			t.addRow(row, tr);
		}
		t.populate();
		t.linkHeaders();
		f.validate = function(parms) {
			parms['id'] = placeid;
			return parms;
		};
		f.submitted = function(data) {
			if(data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			if(eval(data))
				window.location = 'emailalerts.html';
			else
				DOM.setTxt($("submitstatus"), "");
		};
		for(var i = 0; i < assigned.length; i++)
			if(checks[assigned[i]])
				checks[assigned[i]].checked = true;			// XX: should be f.set(assigned[i], true); were it not for IE
		Event.observe($("selectall"), 'click', window, function(evt) {
			for(var k in checks)
				checks[k].checked = true;
			Event.stop(evt);
			return false;
		});
		Event.observe($("clearall"), 'click', window, function(evt) {
			for(var k in checks)
				checks[k].checked = false;
			Event.stop(evt);
			return false;
		});
	}
	else if (pagename == 'userdetails.html') {
		function onEditUser(){
			if($('editusers').checked){
				var elems = f.elForm.elements;
				for(var i = 0; i < elems.length; i++){
					if(elems[i].type == 'checkbox' && elems[i].name != 'driver'){
						elems[i].checked = elems[i].name != 'nonwork';
					}
				}
				DOM.removeClassName($('placesToggle'), 'disabledSwitch');
			}
		}
		function onStandardUser(){
			if(! $('map').checked){
				$('places').checked = false;
				DOM.addClassName($('placesToggle'), 'disabledSwitch');
			}
			else{
				DOM.removeClassName($('placesToggle'), 'disabledSwitch');
			}
		}
		var assigned = assigndata[1];
		var vehicledata = assigndata[0];

		sel = new MultiSelect($('multiselectdiv'), 'Assigned Vehicles', vehicledata, assigned);
		f = new Form("configuration", "saveuser", null, $('submit'));
		f.validate = function (parms) {
			if ($('errorUL')) {
				DOM.hide($('error_div'));
				DOM.removeNodes($('error_content'), $('errorUL'));
			}
			var errorList = DOM.UL({'id': 'errorUL'}, '');

			parms['id'] = gUserId;
			var failure = false;

			if (parms['email'] == '' && !(parms['driver'])){
				DOM.addNodes(errorList, DOM.LI(null, 'New user MUST have an email address - this is used to log into the site'));
				f.setError('email');
				failure = true;
			}
			else if (!validemail(parms['email']) && parms['email'] != ''){
				DOM.addNodes(errorList, DOM.LI(null, 'The email address you have entered appears to be invalid'));
				f.setError('email');
				failure = true;
			}
			else if (!(parms['editusers'] || parms['places'] || parms['map'] || parms['billing'] || parms['editvehicles'] || parms['fmi'] || parms['driver'])){
				DOM.addNodes(errorList, DOM.LI(null, 'User must have at least one permission turned on'));
				failure = true;
			}
			if (failure){
				DOM.show($('error_div'));
				DOM.addNodes($('error_content'), errorList);
				document.body.scrollTop = document.documentElement.scrollTop = 0;
				setError(f.elStatus, 'Validation Error');
				return null;
			}
			parms['password'] = true;
			if(parms['editplaces'])
				parms['viewplaces'] = true;
			if(parms['map'] && avail.indexOf('report') != -1)
				parms['report'] = true;
			if(avail.indexOf('ruc') != -1)
				parms['ruc'] = true;
			if(avail.indexOf('passwd') != -1)
				parms['passwd'] = true;
			if(parms['editvehicles']){
				if(avail.indexOf('configure') != -1)
					parms['configure'] = true;
				if(avail.indexOf('emailalerts') != -1)
					parms['emailalerts'] = true;
			}
			parms['editplaces'] = parms['viewplaces'] = parms['places'];
			delete parms['places'];

			parms['assigned'] = parms['selectto'];
			delete parms['selectto'];
			return parms;
		};
		f.submitted = function (data) {
			if (data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			if (eval(data))
				window.location = 'editusers.html';
			else {
				if ($('errorUL'))
					DOM.removeNodes($('error_content'), $('errorUL'));
				document.body.scrollTop = document.documentElement.scrollTop = 0;
				var errorList = DOM.UL({'id': 'errorUL'}, '');
				if (gUserId == 'new'){
					DOM.addNodes(errorList, DOM.LI(null, 'Error: email address in use or invalid'));
					f.setError('email')
					setError(this.elStatus, 'Email address');
				}
				else{
					DOM.addNodes(errorList, DOM.LI(null, 'Error: unable to update user'));
					setError(this.elStatus, 'Unable to Save');
				}
				DOM.show($('error_div'));
				DOM.addNodes($('error_content'), errorList);
			}
		};
		if(!Browser.isIEPre9){
			Event.observe($('edituserToggle'), 'click', window, onEditUser);
			Event.observe($('mapToggle'), 'click', window, onStandardUser);
		}
		else{
			Event.observe($('editusers'), 'change', window, oneditUser);
			Event.observe($('mapToggle'), 'click', window, onStandardUser);
		}
	}

	else if(pagename == 'driverdetails.html') {
		f = new Form("configuration", "savedriver", null, $('submit'));
		f.validate = function (parms) {
			if ($('errorUL')) {
				DOM.hide($('error_div'));
				DOM.removeNodes($('error_content'), $('errorUL'));
			}
			var errorList = DOM.UL({'id': 'errorUL'}, '');

			parms['id'] = gDriverId;
			var failure = false;
			return parms;
		};
		f.submitted = function (data) {
			if (data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			if (eval(data))
				window.location = 'editdrivers.html';
			else {
				if ($('errorUL'))
					DOM.removeNodes($('error_content'), $('errorUL'));
				document.body.scrollTop = document.documentElement.scrollTop = 0;
				var errorList = DOM.UL({'id': 'errorUL'}, '');
				DOM.addNodes(errorList, DOM.LI(null, 'Error: unable to update driver'));
				setError(this.elStatus, 'Unable to Save');
				DOM.show($('error_div'));
				DOM.addNodes($('error_content'), errorList);
			}
		};

		t = new Table("assignment", '/static/sort_asc.gif', '/static/sort_desc.gif', 1, false);
		window.assignupdate = function(data) {
			data = data.assignment;
			for(var i = 0; i < data.length; i++) {
				var row = data[i];
				var unit = gVehicles[row.unit];
				var name = '';
				if(unit)
					name = unit.name;
				var tr = DOM.TR({'className': 'editLine'}, DOM.TD({'align': 'center'}, name), DOM.TD({'align': 'center'}, formatTime(row.start)), DOM.TD({'align': 'center'}, row.end ? formatTime(row.end) : ""));
				row = [name, row.start, row.end];
				t.addRow(row, tr);
			}
			t.populate();
			t.linkHeaders();
		};
		JsonP.get(gRecentURL + '&jsonp=assignupdate', assignupdate);
	}

	else if(pagename == 'userassign.html') {
		f = new Form("assign", "assign");
		t = new Table("config", '/static/sort_asc.gif', '/static/sort_desc.gif', 0, null, [3]);
		var vehicledata = unitinfo;
		var assigned = assigndata;
		var checks = {};		/* thanks again IE */

		if(assigned.length == vehicledata.length)
			$('selall').checked = true;
		else if(assigned.length != 0)
		    $('selallfor').className = 'tristate';

		for(var i = 0; i < vehicledata.length; i++) {
			var row = vehicledata[i];
			var phone = str(row[2]);
			if(phone)
				phone = '0' + phone;
			var type = row[4] || 'Car';
			var tr = DOM.TR({'className': 'editLine'}, DOM.TD(null, str(row[1])), DOM.TD({'align': 'center'}, type), DOM.TD({'align': 'center'}, phone),
							DOM.style({'textAlign': 'center'}, DOM.TD(null, checks['v' + str(row[0])] = DOM.INPUT({'type': 'checkbox', 'className': 'standardCheck', 'id': 'v' + str(row[0]), 'name': 'v' + str(row[0])}), DOM.LABEL({'htmlFor': 'v' + str(row[0])}))));
			row = [row[1], type, phone, row[0]];
			t.addRow(row, tr);
		}
		t.populate();
		t.linkHeaders();
		f.validate = function(parms) {
			parms['id'] = userid;
			return parms;
		};
		for(var i = 0; i < vehicledata.length; i++) {
			var row = vehicledata[i];
			var event = 'change';
			if(Browser.isIEPre9)
				event = 'click';
			Event.observe($('v' + str(row[0])), event, window, function(evt){
				var allTrue = true;
				var allFalse = true;
				for(var check in checks){
					allTrue = checks[check].checked == true && allTrue;
					allFalse = checks[check].checked == false && allFalse;
				}
				if(allTrue || allFalse){
					$("selall").checked = allTrue;
					$("selallfor").className = '';
				}
				else{
					$("selallfor").className = 'tristate';
				}
			});
		}
		f.submitted = function(data) {
			if(data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			if(eval(data))
				window.location = 'editusers.html';
			else
				DOM.setTxt($("submitstatus"), "");
		};
		for(var i = 0; i < assigned.length; i++)
			if(checks[assigned[i]])
				checks[assigned[i]].checked = true;			// XX: should be f.set(assigned[i], true); were it not for IE

		Event.observe($("selall"), 'click', window, function(evt) {
			$("selallfor").className = '';
			for(var k in checks)
				checks[k].checked = $("selall").checked;
			Event.stop(evt);
			return false;
		});
	}
	else if(pagename == 'contactus.html') {
		f = new Form("contactus", "/myaccount/contact", null, $('submitstatus'));
		f.submitted = contactsubmitted;
		f.validate = contactvalidate;
	}
	else if(pagename == 'feedback.html') {
		f = new Form("feedback", "/myaccount/contact");
		f.submitted = contactsubmitted;
		f.validate = feedbackvalidate;
	}
	else if(pagename == 'resetpassword.html') {
		f = new Form("resetpassword", "doresetpassword", null, $('submit'));
		f.submitted = resetpasswordsubmitted;
		f.validate = passwordvalidate;
	}
	else if(pagename == 'changepasswd.html') {
		f = new Form("changepassword", "changepassword", null, $('submit'));
		f.submitted = passwordsubmitted;
		f.validate = passwordvalidate;
	}
	else if(pagename == 'forgotpassword.html') {
		f = new Form("forgotpassword", "/myaccount/forgotpassword");
		f.submitted = function(data) {
			if(eval(data)) {
				window.location = '/passwordsent.html';
			}
			else
				DOM.setTxt($("submitstatus"), 'Invalid username');
		};
	}
	else if(pagename == 'setpassword.html') {
		f = new Form("changepassword", "changepassword");
		f.submitted = function(data) {
			if(data == 'null' || eval(data))
				window.location = '/myaccount/';
			else
				DOM.setTxt($("submitstatus"), 'Error setting password');
		};
		f.validate = passwordvalidate;
	}
	else if(pagename == 'badpassword.html'){

		f = new Form("badpasslogin", "/myaccount/login", null, $('badpasssubmitstatus'));
		f.submitted = loginsubmitted;
		if(! Cookie.enabled())
			DOM.setTxt($("badpasssubmitstatus"), "Please enable cookies to login to the site");
	}
	else if(pagename == 'accountsettings.html'){
		f = new Form("accountsettings", "accountsettings", "/myaccount/", $('submitstatus'));

		function fmtTime(stringTime){
			time = stringTime.split(':');
			hours = parseFloat(time[0]);
			mins = parseFloat(time[1]);
			meridian = 'AM'
			if(hours > 12){
				hours -= 12
				meridian = 'PM'
			}
			return [hours, mins, meridian];
		}

		startTime = fmtTime(gStartTime);
		endTime = fmtTime(gEndTime);

		f.set('starthour', startTime[0]);
		f.set('startmins', startTime[1]);
		f.set('startAM', startTime[2]);
		f.set('endhour', endTime[0]);
		f.set('endmins', endTime[1]);
		f.set('endAM', endTime[2]);

		f.validate = accountvalidate;
		f.submitted = function(data) {
			if(data == 'null' || eval(data))
				window.location = '/myaccount/';
			else
				setError(this.elStatus, 'Error changing settings');
		};

		Event.observe($('regenhash'), 'click', this, function(evt){
			if($('hash').innerHTML == '')
				conf = true;
			else
				conf = confirm('Regenerate API key?\nAnybody using your current account key will need to be reissued the new key');

			if(conf){
				Ajax.get('generatekey', function(data, status) {
					data = eval(data);
					if(status == 200){
						$('hash').innerHTML = data;
						$('regenhash').value = 'Regenerate';
					}
					else
						DOM.setTxt($('submitstatus'), 'API key generation failed');
				});
			}
		});

		Event.observe($('testalertwebhook'), 'click', this, function(evt) {
			DOM.setTxt($('webhookstatus'), 'Testing...');
			var data = [
				['alerturl', $('alertwebhook').value]
			];
			Ajax.post('testwebhook', Ajax.encode(data), function(data, status) {
				console.log('testwebhook', status, data);
				var msg = 'OK';
				if(status == 200) {
					data = unrepr(data);
					if(data.error)
						msg = data.error;
				}
				else
					msg = 'Error testing webhook. Please try again';
				DOM.setTxt($('webhookstatus'), msg);
			});
		});

		Event.observe($('savealertwebhook'), 'click', this, function(evt) {
			DOM.setTxt($('webhookstatus'), 'Saving...');
			var data = [
				['alerturl', $('alertwebhook').value]
			];
			Ajax.post('setwebhook', Ajax.encode(data), function(data, status) {
				console.log('setwebhook', status, data);
				var msg = 'OK. Webhook Saved';
				if(status == 200) {
					data = unrepr(data);
					if(data.error)
						msg = data.error;
				}
				else
					msg = 'Error saving webhook. Please try again';
				DOM.setTxt($('webhookstatus'), msg);
			});
		});
	}
	else if(pagename == ''){
		var content = $("notice_inner");

		function initMessages(){
			if(gNotices.length > 0){
				if(gNotices.length == 1){
					DOM.hide($("controldiv"));
				}

				if(gNotice >= gNotices.length)
					gNotice = gNotices.length - 1;
				$("notice_inner").innerHTML = gNotices[gNotice].notice;
				DOM.setTxt($("counter"), gNotice + 1 + ' of ' + gNotices.length);
			}
		}

		initMessages(content);

		if(gNotices.length > 0){
			Event.observe($("dismiss"), 'click', window, function(evt) {
				Ajax.get('dismissnotice?noticeid=' + gNotices[gNotice].id, function(data, status){
					if(status == 200){
						if(gNotices.length > 1){
							gNotices.splice(gNotice, 1);
							initMessages();
						}
						else
							DOM.hide($('notice_outer'));
					}
				})
			});

			Event.observe($("move_left"), 'click', window, function(evt){
				if(gNotice == 0)
					gNotice = gNotices.length - 1;
				else
					gNotice -= 1;
				initMessages();
			});

			Event.observe($("move_right"), 'click', window, function(evt){
				if(gNotice >= gNotices.length - 1)
					gNotice = 0;
				else
					gNotice += 1;
				initMessages();
			});
		}


// 			var iFrame = DOM.style({'position': 'absolute', 'left': '100px', 'top': '100px', 'height': '600px', 'width': '600px'}, IFRAME({'src': '/myaccount/messaging.html'}));
// 			DOM.addNodes(document.body, iFrame);
// 		});
	}

	else if(pagename == 'messaging.html'){
		var messageColumns = [
			['', '', 20, cellInboundMessage],
			['Time', 'in', 110, cellFmtTime],
			['Vehicle', 'in', 127],
			['Message', 'in', 405]
		];

		function msgPoll(){
			Ajax.get('getmessages?frm=' + gFrm, function(data, status){
				if (status == 200){
					info = eval(data);
					if(info) {
						gFrm = info.shift();
						for(var i = 0; i < info.length; i++){
							if(info[i][4] in gMessages)
								t.model.raw[gMessages[info[i][4]]][6] = info[i][6];
							else{
								gMessages[info[i][4]] = t.model.raw.length;
								t.model.raw.push(info[i]);
							}
							t.model.doFilter();
							t.model.sort(t.sortBy, t.ascending);
							t.render();
						}
						DOM.hide(t.imgWorking, true);
					}
				}
			});
		}

		t = new LiveGrid($('messagetable'), messageColumns, 10, msgRead, '', displayMsg);

		f = new Form("compose", '/myaccount/sendmessage',  null, $('submitstatus'));

		function toggleCompose(){
			if(gCompose){
				DOM.hide($('compose'));
				$('compose').reset();
				DOM.show($('view'));
				DOM.setTxt($('toggle'), 'Compose');
				gCompose = false;
			}
			else{
				DOM.hide($('view'));
				DOM.show($('compose'));
				DOM.setTxt($('toggle'), 'Read');
				gCompose = true;
			}
		}

		function onchange(){
			if($('body').value.length > 0){
				$('send').disabled = false;
				if($('body').value.length >= 199){
					$('body').value = $('body').value.substring(0, 199);
					DOM.setTxt($('submitstatus'), 'Freeform messages limited to 199 characters');
				}
				else
					DOM.setTxt($('submitstatus'), '');
			}
			else
				$('send').disabled = true;
		}

		Ajax.get('getmessages', function(data, status){
			if(status == 200 && data != ''){
				DOM.hide($('mask'));
				info = eval(data);
				gFrm = info.shift();
				for(var i = 0; i < info.length; i++)
					gMessages[info[i][4]] = i;

				t.populate(info);
			}
		});

		f.validate = function(parms){
			if(parms['units'].length < 1){
				this.setError('units');
				DOM.setTxt($('submitstatus'), 'Please select at least 1 vehicle');
				return null;
			}
			return parms;
		}
		t.sort(1, false);
		t.reload = msgPoll;
		t.filter(function(row) {
			if(row[0] == 'intxt' && ! $('RECEIVED').checked)
				return false;
			if((row[0] == 'outtxt' || row[0] == 'outstop') && ! $('SENT').checked)
				return false;
			return true;
		});

		f.submitted = function(data) {
			if(data == 'null' || eval(data)){
				msgPoll();
				toggleCompose();
			}
			else
				DOM.setTxt($("submitstatus"), 'Error sending message');
		};
		window.setInterval(msgPoll, 15000);
		Event.observe($('body'), 'keyup', window, onchange);
		Event.observe($('body'), 'click', window, onchange);
		Event.observe($('toggle'), 'click', window, toggleCompose);
		Event.observe($('cancel'), 'click', window, toggleCompose);
		Event.observe($('SENT'), 'change', window, function(evt) { t.update(); });
		Event.observe($('RECEIVED'), 'change', window, function(evt) { t.update(); });
		Event.observe(t.grid, 'DOMMouseScroll', t.sb, function(evt){
			t.sb.setpos((t.sb.scroller.scrollTop / t.sb.unitheight) + ((evt.wheelDeltaY * -1) / t.sb.unitheight))
		});
		Event.observe(t.grid, 'mousewheel', t, function(evt){
				t.sb.setpos((t.sb.scroller.scrollTop / t.sb.unitheight) + ((evt.wheelDeltaY * -1) / t.sb.unitheight))
			});
	}

	else if (pagename == 'groupdetails.html') {

		f = new Form('details', '/myaccount/dogroup', null, $('submitgroup'));
		f.validate = validategroup;
		var vehicledata = assigndata[0];
		var assigned = assigndata[1];

		sel = new MultiSelect($('multiselectdiv'), 'Filter Vehicles', vehicledata, assigned);

		if (gGroupId == 'new') {
			Event.observe($('cancel'), 'click', window, function (evt) {
				window.location = '/myaccount/vehiclegroups.html';
			});
		}


		f.submitted = function (data) {
			if (data == 'null') {
				window.location = '/myaccount/';
				return;
			}
			if (eval(data))
				setDone(f.elStatus, 'vehiclegroups.html');
		};


	}
}


window.onload = init;
