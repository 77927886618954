/*#include <tween.js>
#include <dom.js>*/

var $ = require('jsc/base.js').$;
var DOM = require('jsc/dom.js');
var Event = require('jsc/event.js');
var TweenAlgorithm = require('jsc/tween.js').TweenAlgorithm;
var Tween = require('jsc/tween.js').Tween;
var Util = require('jsc/util.js');
var Class = require('jsc/class.js');

var lightBox = module.exports = Class(null, {
	__init__: function(title, btn, url, id, ref){
		this.url = url;
		this.idStr = id;
		this.parent = ref;
		this.__element__ = DOM.DIV(null,
			DOM.style({'display': 'none', 'opacity': '0.8'}, this.overlay = DOM.DIV({'id': 'lbOverlay'+id, 'className': 'black'})),
			DOM.style({'display': 'none', 'top': '30px'}, this.main = DOM.DIV({'id': 'lbMain'+id},
				this.outerContainer = DOM.DIV({'id': 'lbOuterContainer'+id, 'className': 'black'},
					this.topContainer =  DOM.DIV({'id': 'lbTopContainer'+id, 'className': 'black'},
						DOM.style({'width': '700px'}, DOM.DIV({'id': 'lbBottomData'+id, 'className': 'black'},
							DOM.style({'fontWeight': '800'}, DOM.SPAN({'id': 'lbTitleBottom'+id}, title)),
							DOM.SPAN({'className': 'lbNumBottom'}, '')
						)),
						DOM.DIV({'className': 'lbBottomNav'},
							this.close = DOM.DIV({'id': 'lbClose'+id, 'title': 'Close (Esc)', 'className': 'black'}, '')
						)
					),
					DOM.style({'display': 'none'}, this.messageContainer = DOM.DIV({'className': 'lbMessageContainer'}, '')),
					DOM.style({'display': 'none', 'className': 'black'}, this.loading = DOM.DIV({'id': 'lbLoading'+id},
						DOM.DIV({'className': 'lbImage black'}, '')
					))
				)
			))
		);
		var size = Util.getWindowSize();
		if(size.w > 1500){
			var end_w = 773;
		}
		else{
			var end_w = size.w / 2;
		}
		if(size.h > 1100){
			var end_h = 574;
		}
		else{
			var end_h = 2*size.h / 3;
		}
		this.frame = DOM.IFRAME({'className': 'lbIframe black', 'src': this.url, 'frameborder': '0'});

		DOM.place(this.frame, undefined, undefined, end_w - 24, end_h-44);
		DOM.addNodes(this.messageContainer, this.frame);
		Event.observe($(btn), 'click', this, this.show);
		DOM.addNodes(document.body, this.__element__);
	},

	keypress: function(evt){
		if(evt.keyCode == 27)
			this.hide;
	},

	show: function() {
		if (this.overlay.style.display == 'none'){
			var size = Util.getWindowSize();
			if(size.w > 1500){
				var end_w = 773;
			}
			else{
				var end_w = 2 * size.w / 3;
			}
			if(size.h > 1100){
				var end_h = 574;
			}
			else{
				var end_h = 3 * size.h / 4;
			}
			DOM.place(this.frame, undefined, undefined, end_w - 24, end_h-44);
			var frameDoc = this.frame.contentDocument ? this.frame.contentDocument : this.frame.contentWindow.document;

			if(frameDoc.getElementById("alertScroller")){
				frameDoc.getElementById("alertScroller").style.maxHeight = end_h - 159 + 'px';
			}
			DOM.show(this.overlay);
			DOM.show(this.main);
			DOM.show(this.loading);
			var start_w = 243;
			var start_h = 204;
			tween.run(new Tween(null, null, null, TweenAlgorithm.regularEaseIn, start_h, end_h, 1000, this, function(val) {
					$('lbOuterContainer'+this.idStr).style.height = val + 'px';
				}, function() {
					tween.run(new Tween(null, null, null, TweenAlgorithm.regularEaseIn, start_w, end_w, 1000, this, function(val){
						$('lbOuterContainer'+this.idStr).style.width = val + 'px';
					}, function(){
						DOM.hide(this.loading);
						DOM.show(this.messageContainer);
						this.topContainer.style.display = 'block';
					}))
				}.bind(this)
			));
			Event.observe(this.close, 'click', this, this.hide);
			Event.observe(document, 'keypress', this,  this.keypress);
			Event.observe(this.overlay, 'click', this, this.overlayClick);
			Event.observe(this.main, 'click', this, this.mainClick);
		}
	},

	addColumn: function(col){
		var frameDoc = this.frame.contentDocument ? this.frame.contentDocument : this.frame.contentWindow.document;
		DOM.hide(frameDoc.getElementById('noalerts'));
		DOM.show(frameDoc.getElementById('alertTable'));
		var alertTable = frameDoc.getElementById("alertTableBody")
		DOM.addBefore(alertTable, alertTable.firstChild, col);
	},


	horizontalTween: function(){
		tween.run(new Tween(null, null, null, TweenAlgorithm.regularEaseIn, start_w, 773, 1000, this, function(val) {
			this.outerContainer.style.width = val + 'px';
		}.bind(this)));
		DOM.hide(this.loading);
		DOM.show(this.messageContainer);
		DOM.addNodes(this.messageContainer, this.frame);
		this.topContainer.style.display = 'block';
		Event.observe(this.close, 'click', this, this.hide);
		Event.observe(document, 'keypress', this,  this.keypress);
		Event.observe(this.main, 'click', this, this.mainClick);
	},

	overlayClick: function(evt){
		if(evt.srcElement == this.overlay)
			this.hide(evt);
	},

	mainClick: function(evt){
		if(evt.srcElement == this.main)
			this.hide(evt);
	},

	hide: function(evt){
		if(this.idStr == '1'){
			stopPlaySound(this.snd);
		}

		Event.unobserveAll(this.overlay);
		DOM.hide(this.overlay);
		DOM.hide(this.messageContainer);
		DOM.hide(this.topContainer);
		$('lbOuterContainer'+this.idStr).style.height = '204px';
		$('lbOuterContainer'+this.idStr).style.width = "243px";
		DOM.hide(this.main);
		Event.stopObserving(document, 'keypress', this);
		Event.stop(evt);
	}
});
